import styles from "./Calculator.module.scss";

function Calculator() {
  return (
    <div className={styles.container}>
      <div className={styles.columnsContainer}>
        Calcolator
      </div>
    </div>
  );
}

export default Calculator;
