import { useEffect, useState } from "react";
import styles from "./AQIIndex.module.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { api } from "../../../api/api";

const state = [
  "EFFICIENCY.GOOD", 
  "EFFICIENCY.UNHEALTHY_MAX", 
  "EFFICIENCY.UNHEALTHY", 
  "EFFICIENCY.UNHEALTHY_MIN", 
  "EFFICIENCY.HAZARDOUS"];
function AQIIndex() {
  const { i18n, t } = useTranslation();
  const [isNow, setIsNow] = useState(true);
  const [AQIData, setAQIData] = useState(1);
  const [loader, setLoader] = useState(true);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  const activeCycle = useSelector((state) => state.coopsForUser.activeCycle);

  const isToday = chosenCycle
    ? chosenCycle.maxGrowthDay === chosenCycle.settedGrowthDay &&
      chosenCycle.active
    : false;

  useEffect(() => {
      makeRequest(isNow);
  }, [chosenCycle]);

  const getMark = (value = 0) => {
    if (value > 300) return 100;
    if (value < 0) return 0;
    return value / 3;
  };

  const getMarkPopup = (value = 0) => {
    // if (value > 300) return -429; // 238
    // if (value <= 1) return -24;
    if (value <= 150) return {left: "-24px"};
    if (value > 150) return {right: "-24px"};
    // return (value / 0.345) * -1;
  };

  const makeRequest = (isNow = true) => {
    if(!chosenCycle._id) return;
    setLoader(true);
    api.dashboard.getAQIData(
      chosenCycle._id,
      chosenCycle.settedGrowthDay,
      isNow
    ).then(data => {
      setAQIData(data.data);
      setLoader(false);
    }).catch(err => {
      setAQIData(1);
      setLoader(false);
    });
  };

  const handleClickWeeklyAverage = () => {
    setIsNow(false);
    makeRequest(false);
  };

  const handleClickNow = () => {
    setIsNow(true);
    makeRequest(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t("EFFICIENCY.AQI_LEVEL")}</h3>
        <div className={styles.buttons}>
          {activeCycle && (
            <>
              <button
                type="button"
                onClick={handleClickNow}
                className={classNames({
                  [styles.pressed]: isNow,
                  [styles.unpressed]: !isNow,
                })}
              >
                {isToday
                  ? t("EFFICIENCY.NOW_BUTTON")
                  : t("EFFICIENCY.HOURS_24")}
              </button>
              <button
                type="button"
                onClick={handleClickWeeklyAverage}
                className={classNames({
                  [styles.pressed]: !isNow,
                  [styles.unpressed]: isNow,
                })}
              >
                {t("EFFICIENCY.AVERAGE_BUTTON_2")}
              </button>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles.rtlDir]: i18n.language === "he",
        })}
      >
        {loader ? (
          <CircularProgress />
        ) : (
          <>
            
            <div className={styles.bar}>
              {AQIData && (
                <div
                  className={styles.tip}
                  style={{ left: `calc(${getMark(AQIData)}% - 2px)` }}
                >
                  <div className={styles.hint}>
                    {t(state[Math.round(getMark(AQIData)/20)])}
                    
                  </div>
                  <div className={styles.popup} style={{...getMarkPopup(AQIData)}}>
                      <div className={styles.popupHeader}>
                        <div>
                          AQI Level
                          <b>0-50</b>  
                        </div>
                        <div>
                          PM 2.5 (ug/m2)
                          <b>0-12.0</b>  
                        </div>
                      </div>
                      <div className={styles.popupContent}>
                        <div>Health Recommendations (For 24hr Exposure)</div>
                        <b>Air quality is satisfactory and posses little or no risk</b>
                      </div>
                    </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AQIIndex;
