import styles from "./MainPage.module.scss";
import MessagesStack from "../../components/messagesStack/MessagesStack";
import SensorsStack from "../../components/sensorsStack/SensorsStack";
import DeathRate from "../../components/deathRate/DeathRate";
import GrowthRate from "../../components/growthRate/GrowthRate";
import PHIndex from "../../components/healthIndicators/PHIndex/PHIndex";
import WindSoundFeels from "../../components/healthIndicators/WindSoundFeels/WindSoundFeels";
import AQIIndex from "../../components/healthIndicators/AQIIndex/AQIIndex";
import TemperatureHumidityData from "../../components/ventilation/temperatureHumidityData/TemperatureHumidityData";
import HeatStress from "../../components/ventilation/heatStress/HeatStress";

function MainPage() {
  return (
    <div className={styles.container}>
      <div className={styles.columnsContainer}>
        <div className={styles.firstColumn}>
          <div className={styles.lineColumn}>
            <PHIndex />
            <SensorsStack />
          </div>
          <div className={styles.lineColumn}>
            <AQIIndex />
            <WindSoundFeels />
          </div>
          <div className={styles.lineColumn}>
            <HeatStress />
            <TemperatureHumidityData />
          </div>
          <div className={styles.lineColumn}>
            <DeathRate />
            <GrowthRate />
          </div>
        </div>

        <div className={styles.secondColumn}>
          <MessagesStack />
        </div>
      </div>
    </div>
  );
}

export default MainPage;
