import { useTranslation } from "react-i18next";
import styles from "./YAxis.module.scss";

function YAxis({ range: { low }, range: { normal }, range: { high }, range: { critical } }) {
  const { t } = useTranslation();

  // const calculateHighPercentage = (high, low) => {
  //   return ((high - low) / high) * 100;
  // };

  // const calculateLowPercentage = (high, low, none) => {
  //   return ((low - none) / high) * 100;
  // };

  // const calculateNonePercentage = (high, none) => {
  //   return (none / high) * 100;
  // };

  return (
    <div className={styles.container}>
      <div className={styles.yAxis}>
        <div
          className={styles.critical}
        ></div>
        <div
          className={styles.hi}
        ></div>
        <div
          className={styles.normal}
        ></div>
        <div
          className={styles.low}
        ></div>
      </div>

      <div className={styles.tipNames}>
        <div
          className={styles.critical}
        >
          <span>
            {high}-{critical}
          </span>
          {t("VENTILATION.CRITICAL")}
        </div>
        <div
          className={styles.hi}
        >
          <span>
            {normal}-{high}
          </span>
          {t("VENTILATION.HIGH")}
        </div>
        <div
          className={styles.normal}
        >
          <span>
            {low}-{normal}
          </span>
          {t("VENTILATION.NORMAL")}
        </div>
        <div
          className={styles.low}
        >
          <span>0-{low}</span>
          
          {t("VENTILATION.LOW")}
        </div>
      </div>
    </div>
  );
}

export default YAxis;
