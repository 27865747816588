import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../utils/sharedExtraReducers";
// import { changeUpdatingGrowthRateStatus } from "../updatingSlice";

const initialState = {
  getGrowthRateForUser: {
    noramlizedResponse: null,
    status: null,
    error: null,
  },
};

// export const getGrowthRateForUserByCycleIdThunk = createAsyncThunk(
//   "growthRateForUser/getGrowthRateForUserByCycleId",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { chosenCycleId, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingGrowthRateStatus("started"));
//       const response = await api.dashboard.getGrowthRateForUser(chosenCycleId);
//       dispatch(addGrowthRateToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingGrowthRateStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingGrowthRateStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const growthRateForUserSlice = createSlice({
  name: "growthRateForUser",
  initialState,
  reducers: {
    // addGrowthRateToState(state, action) {
    //   const growthRateData = [...action.payload].reduce(
    //     (accum, item) => {
    //       if (item.value) {
    //         accum.current.push([item.day, item.value]);
    //       }
    //       accum.planned.push([item.day, item.expected]);
    //       return accum;
    //     },
    //     { current: [], planned: [] }
    //   );
    //   state.getGrowthRateForUser.noramlizedResponse = growthRateData;
    // },
    removeGrowthRateFromState(state) {
      state.getGrowthRateForUser.noramlizedResponse = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       getGrowthRateForUserByCycleIdThunk.pending,
  //       extraReducers(pending, "getGrowthRateForUser")
  //     )
  //     .addCase(
  //       getGrowthRateForUserByCycleIdThunk.rejected,
  //       extraReducers(rejected, "getGrowthRateForUser")
  //     )
  //     .addCase(
  //       getGrowthRateForUserByCycleIdThunk.fulfilled,
  //       extraReducers(fulfilled, "getGrowthRateForUser")
  //     );
  // },
});

export const { addGrowthRateToState, removeGrowthRateFromState } =
  growthRateForUserSlice.actions;
export default growthRateForUserSlice.reducer;
