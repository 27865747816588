import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/api";
import {
  extraReducers,
  fulfilled,
  pending,
  rejected,
} from "../../../utils/sharedExtraReducers";

export const defaultValue = [
  { temp: "<5", cycle: "-", time: "-", air: "-" },
  { temp: "5-10", cycle: "-", time: "-", air: "-" },
  { temp: "10-15", cycle: "-", time: "-", air: "-" },
  { temp: ">15", cycle: "-", time: "-", air: "-" },
]

const initialState = {
  getMoisterRemovalData: {
    response: defaultValue,
    status: null,
    error: null,
  },
};

export const getMoisterRemovalDataThunk = createAsyncThunk(
  "moisterRemovalForUser/getMoisterRemovalData",
  async function (payload, { rejectWithValue, dispatch }) {
    const { run, air, day, cycelId } = payload;
    if (!air || !run) {
      return dispatch(addMoisterRemovalDataToState({
        payload: defaultValue
      }));
    }
    try {
      const response = await api.dashboard.getVentilationRecomendation(
        run,
        air,
        day,
        cycelId
      );
      dispatch(addMoisterRemovalDataToState(response.data));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const moisterRemovalForUserSlice = createSlice({
  name: "moisterRemovalForUser",
  initialState,
  reducers: {
    addMoisterRemovalDataToState(state, action) {
      const moisterRemovalData = action.payload.map( rowData => {
        return {...rowData,
          cycle: rowData.cycle !== '-' ?
              rowData.cycle.toFixed(2) :
              rowData.cycle,
          air: rowData.air !== '-' ? Math.round(rowData.air * 100) / 100 : '-'
        }
      })
      state.getMoisterRemovalData.response = moisterRemovalData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMoisterRemovalDataThunk.pending,
        extraReducers(pending, "getMoisterRemovalData")
      )
      .addCase(
        getMoisterRemovalDataThunk.rejected,
        extraReducers(rejected, "getMoisterRemovalData")
      )
      .addCase(
        getMoisterRemovalDataThunk.fulfilled,
        extraReducers(fulfilled, "getMoisterRemovalData")
      );
  },
});

export const { addMoisterRemovalDataToState } =
  moisterRemovalForUserSlice.actions;
export default moisterRemovalForUserSlice.reducer;
