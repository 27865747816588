import { makeRequest } from "../makeRequest";

const URL = "/Coop";

export const getCoopById = (coopId) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${coopId}`,
  });
};

export const setCountChickens = (coopId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/countChickens`,
    data
  });
};

export const startCycle = (coopId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/StartCycle`,
    data,
  });
};

export const stopCycle = (coopId) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/StopCycle`,
  });
};

export const updateDeath = (coopId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/UpdateDath`,
    data,
  });
};

export const updateGrowth = (coopId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/UpdateGrowth`,
    data,
  });
};
