import styles from "./Report.module.scss";

function Report() {
  return (
    <div className={styles.container}>
      <div className={styles.columnsContainer}>
        Report
      </div>
    </div>
  );
}

export default Report;
