import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { extraReducers, fulfilled, pending, rejected } from "../../utils/sharedExtraReducers";

const initialState = {
  getAlerts: {
    response: {
      pageIndex: null,
      totalPages: null,
      items: [],
    },
    status: null,
    error: null,
  },
  updateAlert: {
    status: null,
    error: null,
  },
  createAlert: {
    status: null,
    error: null,
  },
};

export const getAlertsThunk = createAsyncThunk(
  "alertsData/getAlerts",
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const response = await api.alerts.getAlerts();
      dispatch(addGettedAlertToState(response.data));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateAlertThunk = createAsyncThunk(
  "alertsData/updateAlert",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      const response = await api.alerts.updateAlert(data);
      dispatch(addUpdatedAlertToState(response.data));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createAlertThunk = createAsyncThunk(
  "alertsData/createAlert",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      const response = await api.alerts.createAlert(data);
      dispatch(addCreatedAlertToState(response.data));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const alertsDataSlice = createSlice({
  name: "alertsData",
  initialState,
  reducers: {
    addGettedAlertToState(state, action) {
      state.getAlerts.response = action.payload;
    },
    addUpdatedAlertToState(state, action) {
      const indexOfChangedItem = state.getAlerts.response.items.findIndex(
        (alert) => alert._id === action.payload._id
      );
      state.getAlerts.response.items.splice(
        indexOfChangedItem,
        1,
        action.payload
      );
    },
    addCreatedAlertToState(state, action) {
      state.getAlerts.response.items.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlertsThunk.pending, extraReducers(pending, "getAlerts"))
      .addCase(getAlertsThunk.rejected, extraReducers(rejected, "getAlerts"))
      .addCase(getAlertsThunk.fulfilled, extraReducers(fulfilled, "getAlerts"))
      .addCase(updateAlertThunk.pending, extraReducers(pending, "updateAlert"))
      .addCase(updateAlertThunk.rejected, extraReducers(rejected, "updateAlert"))
      .addCase(updateAlertThunk.fulfilled, extraReducers(fulfilled, "updateAlert"))
      .addCase(createAlertThunk.pending, extraReducers(pending, "createAlert"))
      .addCase(createAlertThunk.rejected, extraReducers(rejected, "createAlert"))
      .addCase(createAlertThunk.fulfilled, extraReducers(fulfilled, "createAlert"));
  },
});

export const {
  addUpdatedAlertToState,
  addCreatedAlertToState,
  addGettedAlertToState,
} = alertsDataSlice.actions;
export default alertsDataSlice.reducer;
