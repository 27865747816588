export const translationsHE = {
  LOGINPAGE: {
    WELCOME: "ברוכים הבאים לסינורניס",
    USERNAME: "שם משתמש",
    TYPENAME: "שם משתמש",
    PASSWORD: "סיסמא",
    TYPE: "הקלד",
    LOG_IN: "כניסה",
  },

  HEADER: {
    LOG_OUT: "התנתקות",
    TODAY: "היום",
    FARM_NAME: "חווה",
    COOP_NAME: "לול",
    GROWTH_NAME: "מחזור",
    GROWTH_DAY: "יום גידול",
    CYCLE: "מחזור",
    START: "התחל מחזור",
    STOP: "עצור מחזור",
    GROWING_CYCLE: "מחזור גידול",
    FARMS: "חוות",
    ALERTS: "התראות",
    USERS: "משתמשים",
  },

  NEW_GROWINGCYCLE_DIALOG: {
    HEADER: "מחזור גידול חדש",
    CYCLE_NAME: "שם",
    NAME_PLACEHOLDER: "הקלד שם",
    CHICKENS_NUMBER: "מספר עופות",
    NUMBER_PLACEHOLDER: "הקלד מספר",
    GROWTH_DAY: "יום גידול",
    TOTAL_GROWTH_DAY: "מספר ימי גידול",
    SAVE_BUTTON: "שמירה",
  },

  CONFIRMATION_DIALOG: {
    QUESTION: "האם לסיים את מחזור הגידול הפעיל?",
    YES: "כן",
    NO: "לא",
  },

  SENSORS_DATA: {
    SENSORS: "חישנים",
    ON: "דולק",
    OFF: "כבוי",
    COOLING_PAD: "משטח קירור",
    AREA: "אזור",
  },

  SELECT: {
    CHOOSE: "בחר",
  },

  SENSORS: {
    COMPLETE: "שלם",
    FALSE_ALARM: "אזעקת שווא",
    PANDING: "בהמתנה",
    SEVERITY: "חומרה",
    STATUS: "סטטוס",
    CATEGORY: "קטגוריה",
    SENSORS: "התראות",
    CRITICAL_LEVEL: "רמה קריטית",
    HIGH: "גבוה",
    MEDIUM: "בינוני",
    LOW: "נמוך",
    TODAY: "היום",
    FILTER_BY: "סנן לפי",
    SORT_BY: "מיין לפי",
    YESTERDAY: "אתמול",
    NOMESSAGES: "אין הודעות",
  },

  VENTILATION: {
    HEADER: "איורר",
    CLIMATE: "אקלים",
    FIRST_SUBHEADER: "עומס חום לפי אזור",
    NOW_BUTTON: "עכשיו",
    AVERAGE_BUTTON_1: "ממוצע 24-שעות",
    HIGH: "גבוה",
    LOW: "נמוך",
    NORMAL: "רגיל",
    CRITICAL: "קריטי",
    AREA: "אזור",
    SECOND_SUBHEADER: "24 שעות",
    AVERAGE_BUTTON_2: "ממוצע",
    SENSORS_BUTTON: "לפי חיישן",
    TEMP_BUTTON: "טמפרטורה",
    RH_BUTTON: "לחות",
    MAX: "מקסימום",
    MIN: "מינימום",
    THIRD_SUBHEADER: "סילוק לחות",
    RUN_CYCLE: "מחזור פעולה (שניות)",
    ACTIVE_FANS: "מאוורים פעילים",
    AIR_FLOW: "זרימת אוויר (סמ״ק לשעה)",
    FOURTH_SUBHEADER1: "איורר",
    FOURTH_SUBHEADER2: "המלצות",
    CALCULATE_BUTTON: "חישוב",
    TEMP_OUTSIDE: "טמפ חוץ",
    CYCLE: "% ממחזור",
    CYCLE_RUN_TIME: "זמן מחזור",
    AIR: `אוויר (m3)/1Kg`,
  },

  EFFICIENCY: {
    HEADER: "יעילות",
    FIRST_SUBHEADER: "אינדקס בריאות",
    NOW_BUTTON: "עכשיו",
    AVERAGE_BUTTON_2: "ממוצע שבועי",
    AQI_LEVEL: "איכות אוויר",
    HAZARDOUS: "מסוכן",
    UNHEALTHY_MAX: "מקס לא בריא",
    UNHEALTHY: "לא בריא",
    UNHEALTHY_MIN: "דקות לא בריאות",
    GOOD: "תקין",
    HOURS_24: "24-Hr",
  },

  DEATH_RATE: {
    HEADER: "קצב תמותה",
    PLANNED: "מתוכנן",
    CURRENT: "נוכחי",
    NEW_EDIT: "עדכון",
    CHOOSE_DATE: "בחירת תאריך",
    TYPE_NUMBER: "הזן מספר",
    ADD_BUTTON: "הוספה",
    SECOND_HEADER: "קצב גדילה",
    GROWTH_DAY: "יום הצמיחה",
    UPDATE_BUTTON: "עדכון",
    TYPE_WEIGHT: "משקל",
    WEIGHT: "משקל",
    DAY: "יום",
  },

  USER_TABLE: {
    NAME: "שם",
    USER_NAME: "שם משתמש",
    LAST_LOGIN: "כניסה אחרונה",
    ROLE: "הרשאה",
    NAME_LABEL: "שם:",
    NAME_PLACEHOLDER: "הקלד שם",
    USER_NAME_LABEL: "שם משתמש:",
    USER_NAME_PLACEHOLDER: "הקלד שם משתמש",
    NAME_ERROR_1: "אורך שדה מינימלי 2 תווים",
    NAME_ERROR_2: "אורך שדה מינימלי 2 תווים",
    USER_NAME_ERROR_1: "חובה להזין שם משתמש",
    USER_NAME_ERROR_2: "אורך שדה מינימלי 2 תווים",
    USER_ROLE_PLACEHOLDER: "בחר הרשאה",
    USER_ROLE_ERROR: "נדרשת הרשאה",
    USER_ROLE_LABEL: "הרשאות:",
    SELECT_FARMS_LABEL: "חוות:",
    FIRST_NAME: "שם פרטי",
    LAST_NAME: "שם משפחה",
    EMAIL: "אימייל",
    // ROLE: "הרשאות",
    FIRST_NAME_LABEL: "שם פרטי:",
    FIRST_NAME_PLACEHOLDER: "הקלד שם פרטי",
    LAST_NAME_LABEL: "שם משפחה:",
    LAST_NAME_PLACEHOLDER: "הקלד שם משפחה",
    FIRST_NAME_ERROR_1: "חובה להקליד שם פרטי",
    FIRST_NAME_ERROR_2: "אורך שדה מינימלי 2 תווים",
    LAST_NAME_ERROR_1: "חובה להקליד שם משפחה",
    LAST_NAME_ERROR_2: "אורך שדה מינימלי 2 תווים",
    // USER_ROLE_PLACEHOLDER: "Choose user role",
    // USER_ROLE_ERROR: "נדרשת הרשאה",
    // USER_ROLE_LABEL: "הרשאה:",
    // SELECT_FARMS_LABEL: "חוות:",
    ALARM_MESSAGE:
      "יש למלא רק אם נדרש להחליף סיסמא למשתמש",
    PASSWORD_LABEL: "סיסמא:",
    PASSWORD_PLACEHOLDER: "הקלד סיסמא",
    PASSWORD_ERROR: "אורך שדה מינימלי 6 תווים",
    MATCH_PASSWORD_LABEL: "אימות סיסמא:",
    MATCH_PASSWORD_ERROR: "סיסמאות לא תואמות",
    MATCH_PASSWORD_PLACEHOLDER: "אימות סיסמא",
    SAVE_BUTTON: "שמירה",
    RESET_BUTTON: "איפוס",
    ADD_BUTTON: "משתמש חדש",
    TOASTER_UPDATE_ROLE: "הרשאה עודכנה בהצלחה",
    TOASTER_UPDATE_USER: "משתמש עודכן בהצלחה",
  },

  CREATE_USER_FORM: {
    FIRST_NAME_LABEL: "שם פרטי:",
    FIRST_NAME_PLACEHOLDER: "הקלד שם פרטי",
    LAST_NAME_LABEL: "שם משפחה:",
    LAST_NAME_PLACEHOLDER: "הקלד שם משפחה",
    PASSWORD_LABEL: "סיסמא:",
    PASSWORD_PLACEHOLDER: "הקלד סיסמא",
    EMAIL_LABEL: "אימייל:",
    EMAIL_PLACEHOLDER: "הקלד אימייל",
    INPUT_ERROR_1: "חובה להקליד שם פרטי",
    INPUT_ERROR_2: "אורך שדה מינימלי 2 תווים",
    INPUT_ERROR_3: "חובה להקליד שם משפחה",
    INPUT_ERROR_4: "חובה להקליד אימייל",
    INPUT_ERROR_5: "אימייל לא תקין",
    INPUT_ERROR_6: "חובה להקליד סיסמא",
    INPUT_ERROR_7: "אורך שדה מינימלי 6 תווים",
    INPUT_ERROR_8: "סיסמא חייבת להכיל מספר אחד לפחות",
    INPUT_ERROR_9:
      "על הסיסמא להכיל תו מיוחד אחד לפחות (!@#$%^&*?+-) ",
    INPUT_ERROR_9_1: "סיסמא חייבת להכיל סימן מיוחד אחד לפחות",
    INPUT_ERROR_10: "סיסמא חייבת להכיל אות קטנה אחת לפחות",
    INPUT_ERROR_11: "סיסמא חייבת להכיל אות גדולה אחת לפחות",
    TOASTER_USER_CREATE: "משתמש נוצר בהצלחה",
  },

  ALERTS_TABLE: {
    TITLE: "התראות",
    PRIORITY: "עדיפות",
    IS_ACTIVE: "פעיל",
    MESSAGE: "הודעה",
    PARAMS: "פרמטרים",
    PARAM_NAME: "שם פרמטר",
    PARAM_VALUE: "ערך",
  },

  ALERTS_FORM: {
    NAME: "שם ההתראה",
    NAME_PLACEHOLDER: "הקלד שם",
    ACTIVE: "פעיל",
    PRIORITY: "עדיפות:",
    LOW: "נמוך",
    NORMAL: "בינוני",
    HIGH: "גבוה",
    MESSAGE: "הודעה",
    MESSAGE_PLACEHOLDER: "הקלד הודעה",
    MESSAGE_LANG_ENG: "אנגלית:",
    MESSAGE_LANG_HEB: "עברית:",
    RECOMENDATION: "המלצות",
    PARAMS: "פרמטרים",
    PARAMS_NAME_PLACEHOLDER: "שם פרמטר",
    PARAMS_NAME_LABEL: "שם:",
    PARAMS_VALUE_LABEL: "ערך",
    PARAMS_VALUE_PLACEHOLDER: "ערך",
    BUTTON_CREATE: "יצירה",
    BUTTON_UPDATE: "שמירה",
    BUTTON_RESET: "איפוס",
  },

  FARM_TABLE: {
    TOASTER_CREATE: "חווה נוצרה בהצלחה!",
    TOASTER_DELETE: "חווה נמחקה בהצלחה!",
    ADD_FARM_BUTTON: "הוספת חווה",
    TABLE_HEADER: "שם החווה",
    CONFIRM_MESSAGE: "האם ברצונך למחוק את החווה?",
  },

  FARM_FORM: {
    HEADER: "שם החווה:",
    PLACEHOLDER: "הקלד שם חווה",
    SAVE_BUTTON: "שמירה",
    CANCEL_BUTTON: "ביטול",
  },

  UPDATE_FARM_FORM: {
    TOASTER_COOP_CREATED: "לול נוצר בהצלחה!",
    TOASTER_FARM_UPDATED: "שם החווה עודכן בהצלחה!",
    TOASTER_FARM_USERLIST_UPDATED:
      "רשימת משתמשי החווה עודכנה בהצלחה!",
    FARM_NAME_INPUT_LABEL: "שם החווה:",
    FARM_NAME_INPUT_PLACEHOLDER: "הקלד שם",
    SAVE_BUTTON: "שמירה",
    RESET_BUTTON: "איפוס",
    FARM_USERLIST_INPUT_LABEL: "רשימת משתמשים:",
    ADD_COOP_BUTTON: "הוספת לול",
    GO_BACK_BUTTON: "אחורה",
    TABLE_COOP_NAME_HEADER: "שם הלול",
    FARM_NAME_INPUT_ERROR1: "שם חווה נדרש",
    FARM_NAME_INPUT_ERROR2: "אורך שדה מינימלי 2 תווים",
  },

  COOP_FORM: {
    NAME_INPUT_LABEL: "שם הלול:",
    NAME_INPUT_PLACEHOLDER: "הקלד שם ללול",
    SAVE_BUTTON: "שמירה",
    CANCEL_BUTTON: "איפוס",
  },

  UPDATE_COOP_FORM: {
    TOASTER_COOP_UPDATE:
      "פרטי הלול עודכנו בהצלחה",
    TOASTER_COOP_SENSORS_UPDATE: "חישניים ללול עודכנו בהצלחה",
    INPUT_COOP_NAME_LABEL: "שם הלול:",
    INPUT_COOP_NAME_PLACEHOLDER: "הקל שם",
    INPUT_COOP_LAT_LABEL: "Lat:",
    INPUT_COOP_LAT_PLACEHOLDER: "",
    INPUT_COOP_LON_LABEL: "Lon:",
    INPUT_COOP_LON_PLACEHOLDER: "",
    SAVE_BUTTON: "שמירה",
    RESET_BUTTON: "איפוס",
    SENSORS_ERROR_MESSAGE: "ערך חייב להיות יחודי",
    SENSORS_HEADER: "חישניים",
    SENSOR_INPUT_LABEL: "מיקום",
    SENSOR_INPUT_PLACEHOLDER: "מספר חיישן",
    GO_BACK_BUTTON: "אחורה",
    INPUT_COOP_NAME_ERROR1: "חובה להקליד שם לול",
    INPUT_COOP_NAME_ERROR2: "אורך שדה מינימלי 2 תווים",
  },

  CHART: {
    ARIA: "אזור",
    PLANNED: "מתוכנן",
    CURRENT: "נוכחי",
  },
};
