import { Dialog } from "@mui/material";
import React from "react";
import CloseIconButton from "../closeIconButton/CloseIconButton";
import CommonButton from "../commonButton/CommonButton";
import styles from "./ConfirmationDialog.module.scss";
import { useTranslation } from "react-i18next";

export const ConfirmationDialog = ({
  open,
  close,
  confirm,
  confirmingMessage,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: ".63rem",
            boxShadow: "0 .19rem .38rem #00000029",
          },
        },
      }}
    >
      <div className={styles.container}>
        <CloseIconButton
          positionStyles={{
            top: "1.13rem",
            right: "1rem",
          }}
          handleClick={close}
        />

        <p>{confirmingMessage}</p>
        <div className={styles.buttonsStack}>
          <CommonButton
            buttonText={t("CONFIRMATION_DIALOG.YES")}
            handleClick={confirm}
            buttonStyles={{
              backgroundColor: "#f11818",
            }}
          />
          <CommonButton
            buttonText={t("CONFIRMATION_DIALOG.NO")}
            handleClick={close}
            buttonStyles={{
              backgroundColor: "#0acf62",
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
