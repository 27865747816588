import { useEffect, useState } from "react";
import styles from "./PHIndex.module.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
// import {
//   addPHIDataToState,
//   getPHIDataThunk,
// } from "../../../store/dashboardSlices/efficiencySlices/PHIForUserSlice";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
// import useUpdate from "../../../hooks/useUpdate";
import {ReactComponent as SmileGood} from '../../../../assets/efficiency/smile-good.svg';
import { api } from "../../../api/api";


function PHIndex() {
  const { i18n, t } = useTranslation();
  // const dispatch = useDispatch();
  const [isNow, setIsNow] = useState(true);
  const [PHIData, setPHIData] = useState(null);
  const [loader, setLoader] = useState(true);
  const activeCycle = useSelector((state) => state.coopsForUser.activeCycle);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  // const PHIData = useSelector((state) => state.PHIForUser.getPHIData.response);

  // const downloadingStatus = useSelector(
  //   (state) => state.PHIForUser.getPHIData.status
  // );
  // const updatingStatus = useSelector((state) => state.updating.PHI);

  const isToday = chosenCycle
    ? chosenCycle.maxGrowthDay === chosenCycle.settedGrowthDay &&
      chosenCycle.active
    : false;



  const makeRequest = (isNow = true) => {
    if(!chosenCycle._id) return;
    setLoader(true);
    api.dashboard.getPHIData(chosenCycle._id, chosenCycle.settedGrowthDay, isNow).then(data => {
      setPHIData(data.data);
      setLoader(false);
    });
    // dispatch(getPHIDataThunk(payload));
  };

  // useUpdate(makeRequest, 1, isNow);

  const handleClickWeeklyAverage = () => {
    setIsNow(false);
    makeRequest(false);
  };

  const handleClickNow = () => {
    setIsNow(true);
    makeRequest(true);
  };

  const getPosition = (value) => {
    switch (i18n.language) {
      case "en":
        return value;

      case "he":
        return 100 - value;

      default:
        return null;
    }
  };

  useEffect(() => {
      makeRequest()
    // if (!chosenCycle.settedGrowthDay) {
    //   dispatch(addPHIDataToState(null));
    // }

    // if (chosenCycle._id) {
    //   makeRequest(isNow);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCycle]);

  return (
    <div className={styles.container}>
      <div className={styles.efficiency}>
        <h3>{t("EFFICIENCY.HEADER")}</h3>
        <SmileGood className={styles.icon} />
        <div
          className={
            loader
              ? styles.containerForSpinner
              : styles.value
          }
        >
          {loader ? (
            <CircularProgress />
          ) : (
            <>{PHIData ? <>{PHIData.efficiency.toFixed(1)}</> : " - "}%</>
          )}
        </div>
      </div>
      <div className={styles.PHIndexBar}>
        <div className={styles.headerWithButton}>
          <h3>{t("EFFICIENCY.FIRST_SUBHEADER")}</h3>
          <div className={styles.buttons}>
            {activeCycle && (
              <>
                <button
                  type="button"
                  onClick={handleClickNow}
                  className={classNames({
                    [styles.pressed]: isNow,
                    [styles.unpressed]: !isNow,
                  })}
                >
                  {isToday
                    ? t("EFFICIENCY.NOW_BUTTON")
                    : t("EFFICIENCY.HOURS_24")}
                </button>
                <button
                  type="button"
                  onClick={handleClickWeeklyAverage}
                  className={classNames(styles.averageButton, {
                    [styles.pressed]: !isNow,
                    [styles.unpressed]: isNow,
                  })}
                >
                  {t("EFFICIENCY.AVERAGE_BUTTON_2")}
                </button>
              </>
            )}
          </div>
        </div>

        <div
          className={
            loader
              ? styles.containerForSpinner
              : styles.bar
          }
        >
          {loader ? (
            <CircularProgress />
          ) : (
            <>
              <div className={styles.barLine} />
              <span className={styles.poorText}>Poor</span>
              <span className={styles.goodText}>Good</span>
              {PHIData && (
                <div
                  className={styles.pointer}
                  style={{
                    left: `calc(${getPosition(PHIData.phi)}% - .13rem)`,
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PHIndex;
