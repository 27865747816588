import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { stopCycleThunk } from "../../../store/coopsForUserSlice";
import { ConfirmationDialog } from "../../shared-components/confirmationDialog/ConfirmationDialog";
import Button from "../../UI/Button/Button";

export const StartStopButton = ({ openNewCycleDialog }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeCycle = useSelector((state) => state.coopsForUser.activeCycle);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const chosenCoopId = useSelector(
    (state) => state.coopsForUser.getCoopById.response?._id
  );

  const stopActiveCycle = (coopId) => {
    dispatch(stopCycleThunk(coopId));
    setOpenConfirmDialog(false);
  };

  const buttonAction = () => {
    return activeCycle ? (
      <span>{t("HEADER.STOP")}</span>
    ) : (
      <span>{t("HEADER.START")}</span>
    );
  };

  return (
    <>
      <Button
        label={buttonAction()}
        styleType="greenBorder"
        onClick={
          activeCycle ? () => setOpenConfirmDialog(true) : openNewCycleDialog
        }
      />
      <ConfirmationDialog
        open={openConfirmDialog}
        close={() => setOpenConfirmDialog(false)}
        confirm={() => stopActiveCycle(chosenCoopId)}
        confirmingMessage={t("CONFIRMATION_DIALOG.QUESTION")}
      />
    </>
  );
};
