import styles from "./DialogForNewGrowingCycle.module.scss";
import { Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import CloseIconButton from "../../components/shared-components/closeIconButton/CloseIconButton";
import CommonButton from "../../components/shared-components/commonButton/CommonButton";
import CommonInput from "../shared-components/commonInput/CommonInput";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

function DialogForNewGrowingCycle({ open, closeNewCycleDialog }) {
  const { t } = useTranslation();
  const choosenCoopId = useSelector(
    (state) => state.coopsForUser.getCoopById.response?._id
  );

  const defaultValues = {
    name: moment().format("YYYY-MM-DD-hh-mm-ss"),
    chickens: "",
    currentDay: 1,
    totalDays: 38,
  };

  const { reset, handleSubmit, control, setValue } = useForm({
    mode: "all",
    defaultValues,
  });

  const onSubmit = (data) => {
    const dataForSubmit = { ...data, coopId: choosenCoopId };
    reset(defaultValues);
    closeNewCycleDialog(dataForSubmit);
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeNewCycleDialog()}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: ".63rem",
            boxShadow: "0 .19rem .38rem #00000029",
          },
        },
      }}
    >
      <div className={styles.container}>
        <CloseIconButton
          positionStyles={{
            top: "1.13rem",
            right: "1rem",
          }}
          rules={{
            required: true,
            defaultValues: 1,
          }}
          handleClick={closeNewCycleDialog}
        />

        <h2>{t("NEW_GROWINGCYCLE_DIALOG.HEADER")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonInput
            labelText={t("NEW_GROWINGCYCLE_DIALOG.CYCLE_NAME")}
            placeholder={t("NEW_GROWINGCYCLE_DIALOG.NAME_PLACEHOLDER")}
            control={control}
            name="name"
            rules={{ 
              required: true
             }}
          />

          <CommonInput
            labelText={t("NEW_GROWINGCYCLE_DIALOG.CHICKENS_NUMBER")}
            placeholder={t("NEW_GROWINGCYCLE_DIALOG.NUMBER_PLACEHOLDER")}
            inputType="number"
            control={control}
            name="chickens"
            rules={{
              required: true,
              min: 1,
            }}
            setValue={setValue}
          />

          <div className={styles.durationStack}>
            <CommonInput
              labelText={t("NEW_GROWINGCYCLE_DIALOG.GROWTH_DAY")}
              placeholder={t("NEW_GROWINGCYCLE_DIALOG.NUMBER_PLACEHOLDER")}
              inputType="number"
              control={control}
              name="currentDay"
              rules={{
                required: true,
                min: 1,
              }}
              setValue={setValue}
            />

            <CommonInput
              labelText={t("NEW_GROWINGCYCLE_DIALOG.TOTAL_GROWTH_DAY")}
              placeholder={t("NEW_GROWINGCYCLE_DIALOG.NUMBER_PLACEHOLDER")}
              inputType="number"
              control={control}
              name="totalDays"
              rules={{
                required: true,
                min: 1,
              }}
              setValue={setValue}
            />
          </div>

          <CommonButton
            buttonText={t("NEW_GROWINGCYCLE_DIALOG.SAVE_BUTTON")}
            buttonType="submit"
            buttonStyles={{
              backgroundColor: "#0ACF83",
            }}
          />
        </form>
      </div>
    </Dialog>
  );
}

export default DialogForNewGrowingCycle;
