import { makeRequest } from "../makeRequest";

const URL = "/Farms";

export const getFarms = () => {
  return makeRequest({
    method: "GET",
    url: URL,
  });
};
