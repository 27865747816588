import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button";
// import useIsDownloading from "../../../hooks/useIsDownloading";
import { setGrowthDayToCycle } from "../../../store/coopsForUserSlice";
function TodayButton() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
    const cyclesArray = useSelector((state) => state.coopsForUser.cyclesArray);
    // const isButtonBright = chosenCycle
    //   ? chosenCycle.maxGrowthDay === chosenCycle.settedGrowthDay &&
    //     chosenCycle.active
    //   : false;
  
    // const isDownloading = useIsDownloading();
  
    const handleClick = () => {
      if (chosenCycle.maxGrowthDay !== chosenCycle.settedGrowthDay)
        dispatch(
          setGrowthDayToCycle({
            cyclesArray,
            chosenCycleId: chosenCycle._id,
            newValue: chosenCycle.maxGrowthDay,
          })
        );
    };
  
    return (
      <Button
        label={t("HEADER.TODAY")}
        // label={!chosenCycle.active || isDownloading}
        onClick={handleClick}
        styleType='lr'
        // disabled={!chosenCycle.active || isDownloading}
        // style={{ cursor: chosenCycle.active ? "pointer" : "auto" }}
      />
    );
}
  
  export default TodayButton;