export const chooseColor = (value, range) => {
  let result = "grey";
  if (range) {
    result =
      value >= range["dangerHigh"] || value <= range["dangerLow"]
        ? "#F24E1E"
        : (value >= range["poorHigh"] && value < range["dangerHigh"]) ||
          (value <= range["poorLow"] && value > range["dangerLow"])
        ? "#FFB23A"
        : "#0ACF83";
  }
  return result;
};

export const chooseMark = (value, range) => {
  let result =
    value >= range["dangerHigh"] || value <= range["dangerLow"]
      ? "image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='white' /%3E%3Ccircle cx='6' cy='6' r='3' fill='%23F24E1E' /%3E%3C/svg%3E"
      : (value >= range["poorHigh"] && value < range["dangerHigh"]) ||
        (value <= range["poorLow"] && value > range["dangerLow"])
      ? "image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='white' /%3E%3Ccircle cx='6' cy='6' r='3' fill='%23FFB23A' /%3E%3C/svg%3E"
      : "image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='white' /%3E%3Ccircle cx='6' cy='6' r='3' fill='%230ACF83' /%3E%3C/svg%3E";
  return result;
};
