import style from "./style.module.scss";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from "classnames";

function Select({list = [], activeSelect = "", handleSelect = () => {}, name = "Select"}) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState('Choose');
    
    useEffect(() => {
        setSelected(activeSelect);
    }, [activeSelect])

    return (
        <div className={classNames(style.select, {[style.open]: open})}>
            <div className={style.selectName} onClick={() => {
                setOpen(!open);
            }}>
                {open ? 'Choose': selected.name || name}
                <KeyboardArrowDownIcon className={style.downIcon}/></div>
            <div className={style.selectListRow}
            onClick={() => {
                setOpen(!open);
            }}>
                <div className={style.selectList}>
                    {/* {JSON.stringify(list)} */}
                    {list.map((item) => 
                        <div key={item._id} className={style.selectListItem} onClick={() => handleSelect(item)}>
                            {item.name}
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}

export default Select;
