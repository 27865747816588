import classNames from 'classnames';
import styles from './Select.module.scss'
import { useEffect, useState } from 'react';
import OutsideAlerter from '../../../hooks/useOutsideAlerter';
function Select({name="", placeholder="", handleSelect = () => {}, list = [], selected = {}}) {
  const [select, setSelect] = useState({
    value: '',
    name: '',
    id: ''
  });
  const [isOpen, setIsOpen] = useState(false);

  const handlerSelect = select => {
    setSelect(select);
    handleSelect(select);
  }

  useEffect(() => {
    if(selected._id || selected.id) {
      setSelect({
        name: selected.name,
        id: selected._id || selected.id
      });  
    } 
    
  }, [selected]);

  return (  
    <div className={styles.selectContent}>
      <label>
        {name}
      </label>

      <OutsideAlerter handler={()=>{setIsOpen(false)}}>
        <div className={styles.select} onClick={()=>{setIsOpen(!isOpen)}}>
          <div className={classNames(styles.selected, {[styles.placeholder]: !select.name})}>{select.name || placeholder}</div>
          <div className={classNames(styles.list, {[styles.open]: isOpen})}>
            {list.map((item, index) => (
              <div
                key={item.id || item._id}
                onClick={() => handlerSelect(item)}
                className={classNames(styles.item, {[styles.active]: item.value === select.value})}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </OutsideAlerter>
      
    </div>
  );
}

export default Select;