import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../api/api";
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../utils/sharedExtraReducers";
// import { changeUpdatingAlertsStatus } from "../updatingSlice";
// dayjs.extend(utc);

const initialState = {
  getAlertsForUser: {
    response: null,
    status: null,
    error: null,
  },
};

// export const getAlertsForUserByCycleIdThunk = createAsyncThunk(
//   "alertsForUser/getAlertsForUserByCycleId",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { cycleId, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingAlertsStatus("started"));
//       const response = await api.dashboard.getAlertsForUser(cycleId);
//       // dispatch(addAlertsToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingAlertsStatus("finished"));
//       return response.data;
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingAlertsStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const alertsForUserSlice = createSlice({
  name: "alertsForUser",
  initialState,
  reducers: {
    // addAlertsToState(state, action) {
    //   const alerts = [...action.payload];
    //   alerts.sort((a, b) => {
    //     return dayjs(b.created) - dayjs(a.created);
    //   });
    //   state.getAlertsForUser.response = alerts;
    // },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       getAlertsForUserByCycleIdThunk.pending,
  //       extraReducers(pending, "getAlertsForUser")
  //     )
  //     .addCase(
  //       getAlertsForUserByCycleIdThunk.rejected,
  //       extraReducers(rejected, "getAlertsForUser")
  //     )
  //     .addCase(
  //       getAlertsForUserByCycleIdThunk.fulfilled,
  //       extraReducers(fulfilled, "getAlertsForUser")
  //     );
  // },
});

// export const { addAlertsToState } = alertsForUserSlice.actions;
export default alertsForUserSlice.reducer;
