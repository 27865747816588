import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import {
  extraReducers,
  fulfilled,
  pending,
  rejected,
} from "../../utils/sharedExtraReducers";

const initialState = {
  getUsers: {
    response: null,
    status: null,
    error: null,
  },
  createUser: {
    status: null,
    error: null,
  },
  updateUser: {
    status: null,
    error: null,
  },
  updateUserRole: {
    status: null,
    error: null,
  },
};

export const getUsersThunk = createAsyncThunk(
  "usersData/getUsers",
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const response = await api.adminUsers.getUsers();
      dispatch(addGettedUsersToState(response.data));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createUserThunk = createAsyncThunk(
  "usersData/createUser",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      await api.adminUsers.createUser(data);
      dispatch(getUsersThunk());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  "usersData/updateUser",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      await api.adminUsers.updateUser(data);
      dispatch(getUsersThunk());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserRoleThunk = createAsyncThunk(
  "usersData/updateUserRole",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      await api.adminUsers.updateUserRole(data);
      dispatch(getUsersThunk());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const usersDataSlice = createSlice({
  name: "usersData",
  initialState,
  reducers: {
    addGettedUsersToState(state, action) {
      state.getUsers.response = action.payload;
    },
    setCreateUserStatusToNull(state) {
      state.createUser.status = null;
    },
    setUpdateUserRoleStatusToNull(state) {
      state.updateUserRole.status = null;
    },
    setUpdateUserStatusToNull(state) {
      state.updateUser.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersThunk.pending, extraReducers(pending, "getUsers"))
      .addCase(getUsersThunk.rejected, extraReducers(rejected, "getUsers"))
      .addCase(getUsersThunk.fulfilled, extraReducers(fulfilled, "getUsers"))
      .addCase(
        createUserThunk.fulfilled,
        extraReducers(fulfilled, "createUser")
      )
      .addCase(createUserThunk.pending, extraReducers(pending, "createUser"))
      .addCase(createUserThunk.rejected, extraReducers(rejected, "createUser"))
      .addCase(
        updateUserThunk.fulfilled,
        extraReducers(fulfilled, "updateUser")
      )
      .addCase(updateUserThunk.pending, extraReducers(pending, "updateUser"))
      .addCase(updateUserThunk.rejected, extraReducers(rejected, "updateUser"))
      .addCase(
        updateUserRoleThunk.fulfilled,
        extraReducers(fulfilled, "updateUserRole")
      )
      .addCase(
        updateUserRoleThunk.pending,
        extraReducers(pending, "updateUserRole")
      )
      .addCase(
        updateUserRoleThunk.rejected,
        extraReducers(rejected, "updateUserRole")
      );
  },
});

export const {
  addGettedUsersToState,
  setCreateUserStatusToNull,
  setUpdateUserRoleStatusToNull,
  setUpdateUserStatusToNull,
} = usersDataSlice.actions;
export default usersDataSlice.reducer;
