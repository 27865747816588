import { makeRequest } from "../makeRequest";

const URL = "/Admin/Users";

export const getUsers = () => {
  return makeRequest({
    method: "GET",
    url: `${URL}`,
  });
};

export const createUser = (data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}`,
    data,
  });
};

export const updateUser = ({ id, ...data }) => {
  return makeRequest({
    method: "PUT",
    url: `${URL}/${id}`,
    data,
  });
};

export const updateUserRole = ({ id, ...data }) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${id}/Roles`,
    data,
  });
};
