import styles from "./PasswordInput.module.scss";
import showIcon from "../../../../assets/icons/icon-view.png";
import hideIcon from "../../../../assets/icons/icon-hide.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useController } from "react-hook-form";
import classNames from "classnames";

function PasswordInput({
  labelText,
  placeholder,
  control,
  name,
  rules,
  isErrorShown = false,
}) {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ control, name, rules });

  const { i18n } = useTranslation();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const showHidePassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <label className={styles.inputLabel}>
      <span>{labelText}</span>
      <div className={styles.inputContainer}>
        <input
          {...field}
          type={isShowPassword ? "text" : "password"}
          placeholder={placeholder}
          autoComplete="new-password"
        />
        {!isShowPassword && (
          <img
            src={showIcon}
            alt="Show"
            onClick={showHidePassword}
            className={classNames({
              [styles.iconLTR]: i18n.language === "he",
              [styles.iconRTL]: i18n.language === "en",
            })}
          />
        )}
        {isShowPassword && (
          <img
            src={hideIcon}
            alt="Hide"
            onClick={showHidePassword}
            className={classNames({
              [styles.iconLTR]: i18n.language === "he",
              [styles.iconRTL]: i18n.language === "en",
            })}
          />
        )}
        {error && isErrorShown && isTouched && (
          <p className={styles.errorMessage}>{error.message}</p>
        )}
      </div>
    </label>
  );
}

export default PasswordInput;
