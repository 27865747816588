import styles from "./ModalEdit.module.scss";
import Modal from "../../modal/Modal";
import { useTranslation } from "react-i18next";
import Select from "../select/Select";
// import Input from "../input/Input";
import InputModal from "../../UI/inputModal/Input";
import Button from "../../UI/Button/Button";
import { useEffect, useState } from "react";
import { getDevicesArea, setDeviceArea } from "../../../api/requests/device";
import { useSelector } from "react-redux";
import { chooseColor } from "../../../utils/chooseColor";

function ContentModal({isShow = false, setShow = () => {}}) {
  const { i18n, t } = useTranslation();
  const [area1, setArea1] = useState('');
  const [area2, setArea2] = useState('');
  const [area3, setArea3] = useState('');
  const [chosenCoop, setChosenCoop] = useState('');
  const [coopDevices, setCoopDevices] = useState({});

  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  const chosenFarm = useSelector((state) => state.farmsForUser.chosenFarmWithinSession);

  const handleSave = () => {
    if(!chosenCoop._id) return;
    setDeviceArea(chosenCoop._id, {
      area1,
      area2,
      area3
    }).then(() => {
      setShow(false);
    })
  }

  useEffect(() => {
    if(!chosenFarm) return;
    setChosenCoop(old => {
      return chosenFarm.coops.find(coop => coop._id === chosenCycle.coopId);  
    });
  }, [chosenFarm]);

  useEffect(() => {
    if(!chosenCoop?._id) return;

    getDevicesArea(chosenCoop._id).then((data) => {
      setCoopDevices(data)
    });
  }, [chosenCoop]);
  return (  
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <h3>Sensors Setting</h3>
            <div className={styles.close} onClick={() => setShow(false)}>+</div>
          </div>
          <div className={styles.bottom}>
            <Select 
              name="Coop Name" 
              placeholder="Name" 
              list={chosenFarm.coops} 
              selected={chosenCoop}
              handleSelect={(e)=>{setChosenCoop(e)}}
            />
            <Select name="Coop Location" placeholder="Location" list={[]} handleSelect={()=>{}}/>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.contentItem}>
            <div className={styles.label}>Sensor 1</div>
            <InputModal placeholder="xxxxxxxx" name="ID" defaultValue={coopDevices?.a?.deviceId?.deviceId} onClick={(e) => setArea1(e)}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Sensor 2</div>
            <InputModal placeholder="xxxxxxxx" name="ID" defaultValue={coopDevices?.a?.deviceId?.deviceId} onClick={(e) => setArea2(e)}/>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.label}>Sensor 3</div>
            <InputModal placeholder="xxxxxxxx" name="ID" defaultValue={coopDevices?.a?.deviceId?.deviceId} onClick={(e) => setArea3(e)}/>
          </div>
        </div>

        <div className={styles.buttons} onClick={handleSave}>
          <Button label={'Save'}/>
        </div>
      </div>
    </>
  );
}

function ModalEdit({isShow = false, setShow}) {

  return (
    isShow && <>
      <Modal contentModal={<ContentModal isShow={isShow} setShow={setShow}/>}  setShow={setShow} />
    </>
  );
}

export default ModalEdit;
