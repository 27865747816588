import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../utils/sharedExtraReducers";
// import { changeUpdatingDeathRateStatus } from "../updatingSlice";

const initialState = {
  getDeathRateForUser: {
    noramlizedResponse: null,
    status: null,
    error: null,
  },
  cycleIdOfDeathRateToState: null,
};

// export const getDeathRateForUserByCycleIdThunk = createAsyncThunk(
//   "deathRateForUser/getDeathRateForUserByCycleId",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { chosenCycleId, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingDeathRateStatus("started"));
//       const response = await api.dashboard.getDeathRateForUser(chosenCycleId);
//       dispatch(addDeathRateToState(response.data));
//       dispatch(addCycleIdOfDeathRateToState(chosenCycleId));
//       if (isUpdating) dispatch(changeUpdatingDeathRateStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingDeathRateStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const deathRateForUserSlice = createSlice({
  name: "deathRateForUser",
  initialState,
  reducers: {
    // addDeathRateToState(state, action) {
    //   const deathRateData = [...action.payload].reduce(
    //     (accum, item) => {
    //       let itemValue = item.value;
    //       let itemExpected = item.expected;
    //       if (itemValue) {
    //         itemValue = +itemValue.toFixed(2);
    //       }
    //       if (itemExpected) {
    //         itemExpected = +itemExpected.toFixed(2);
    //       }
    //       accum.current.push([item.day, itemValue]);
    //       accum.planned.push([item.day, itemExpected]);
    //       return accum;
    //     },
    //     { current: [], planned: [] }
    //   );
    //   state.getDeathRateForUser.noramlizedResponse = deathRateData;
    // },
    addCycleIdOfDeathRateToState(state, action) {
      state.cycleIdOfDeathRateToState = action.payload;
    },
    removeDeathRateFromState(state) {
      state.getDeathRateForUser.noramlizedResponse = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       getDeathRateForUserByCycleIdThunk.pending,
  //       extraReducers(pending, "getDeathRateForUser")
  //     )
  //     .addCase(
  //       getDeathRateForUserByCycleIdThunk.rejected,
  //       extraReducers(rejected, "getDeathRateForUser")
  //     )
  //     .addCase(
  //       getDeathRateForUserByCycleIdThunk.fulfilled,
  //       extraReducers(fulfilled, "getDeathRateForUser")
  //     );
  // },
});

export const {
  addDeathRateToState,
  removeDeathRateFromState,
  addCycleIdOfDeathRateToState,
} = deathRateForUserSlice.actions;
export default deathRateForUserSlice.reducer;
