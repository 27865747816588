import styles from "./CloseIconButton.module.scss";

function CloseIconButton({positionStyles, handleClick, type}) {
  return (
    <button
      type={type}
      className={styles.closeIconButton}
      onClick={handleClick}
      style={positionStyles}
    >
      <svg width="11" height="11" xmlns="http://www.w3.org/2000/svg">
        <g>
          <line
            id="svg_1"
            y2="10"
            x2="10"
            y1="1"
            x1="1"
            strokeWidth="2"
            stroke="#414141"
            fill="none"
          />
          <line
            id="svg_2"
            y2="10"
            x2="1"
            y1="1"
            x1="10"
            strokeWidth="2"
            stroke="#414141"
            fill="none"
          />
        </g>
      </svg>
    </button>
  );
}

export default CloseIconButton;
