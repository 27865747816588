import { useEffect, useState } from "react";
import styles from "./HeatStress.module.scss";
import BarChart from "./barChart/BarChart.js";
import classNames from "classnames";
import { useSelector } from "react-redux";
// import {
//   addVentilationDataToState,
//   getVentilationDataThunk,
// } from "../../../store/dashboardSlices/ventilationSlices/ventilationDataForUserSlice";
import { useTranslation } from "react-i18next";
// import useUpdate from "../../../hooks/useUpdate";
import { api } from "../../../api/api.js";
import { CircularProgress } from "@mui/material";

function HeatStress() {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const [isNow, setIsNow] = useState(true);
  const [ventilationData, setVentilationData] = useState();
  const [loader, setLoader] = useState(true);
  const activeCycle = useSelector((state) => state.coopsForUser.activeCycle);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  const isToday = chosenCycle
    ? chosenCycle.maxGrowthDay === chosenCycle.settedGrowthDay &&
      chosenCycle.active
    : false;

  useEffect(() => {
    // if (!chosenCycle.settedGrowthDay) {
      // dispatch(addVentilationDataToState(null));
    // }

    // if (chosenCycle?._id) {
      makeRequest(isNow);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCycle]);

  useEffect(() => {
    if (!isToday) {
      setIsNow(false);
    }
  }, [isToday]);

  const handleClickNow = () => {
    setIsNow(true);
    makeRequest(true);
  };

  const handleClickAverage = () => {
    setIsNow(false);
    makeRequest(false);
  };

  const makeRequest = (isNow = true) => {
    if(!chosenCycle._id) return;
    setLoader(true);
    api.dashboard.getVentilationData(chosenCycle._id, chosenCycle.settedGrowthDay, isNow).then(
      (data) => {
        setVentilationData(data);
        setLoader(false);
      }
    );
    // const payload = {
    //   cycleId: chosenCycle?._id,
    //   data: chosenCycle.settedGrowthDay,
    //   isNow,
    //   isUpdating,
    // };
    // dispatch(getVentilationDataThunk(payload));
  };

  

  // useUpdate(makeRequest, 1, isNow);

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <h3>{t("VENTILATION.HEADER")}</h3>
        <div className={styles.buttonContainer}>
          {activeCycle && (
            <>
              {isToday && (
                <button
                  type="button"
                  onClick={handleClickNow}
                  className={classNames(styles.nowButton, {
                    [styles.pressed]: isNow,
                    [styles.unpressed]: !isNow,
                  })}
                >
                  {t("VENTILATION.NOW_BUTTON")}
                </button>
              )}
              <button
                type="button"
                onClick={handleClickAverage}
                className={classNames(styles.averageButton, {
                  [styles.pressed]: !isNow,
                  [styles.unpressed]: isNow,
                })}
              >
                {t("VENTILATION.AVERAGE_BUTTON_1")}
              </button>
            </>
          )}
        </div>
      </div>
      {loader ? (
        <div className={styles.spinnerContainer}>
          <CircularProgress />
        </div>
      ) : (
        <BarChart ventilationData={ventilationData}/>
      )}
    </div>
  );
}

export default HeatStress;
