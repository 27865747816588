import styles from "./style.module.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";
import DialogForNewGrowingCycle from "../../../dialogForNewGrowingCycle/DialogForNewGrowingCycle";
import ProcessManagementBar from "../../processManagementBar/processManagementBar";
import { StartStopButton } from "../../startStopButton/StartStopButton";
import {
  // setGrowthDayToCycle,
  startCycleThunk,
} from "../../../../store/coopsForUserSlice";
// import classNames from "classnames";
// import useIsDownloading from "../../../../hooks/useIsDownloading";
// import Button from "../../../UI/Button/Button";


function DashboardHeader() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const openNewCycleDialog = () => {
    setOpen(true);
  };

  const closeNewCycleDialog = (data) => {
    if (data) {
      dispatch(startCycleThunk(data));
    }
    setOpen(false);
  };

  return (
    <>
        <div className={styles.secondBlock}>
          <ProcessManagementBar />
          <div className={styles.endBlock}>
            <StartStopButton openNewCycleDialog={openNewCycleDialog} />
          </div>
          
        </div>
        <DialogForNewGrowingCycle
            closeNewCycleDialog={closeNewCycleDialog}
            open={open}
        />
    </>
  );
}

export default DashboardHeader;
