import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useIsDownloading() {
  const [isDownloading, setIsDownloading] = useState(false);

  const getSensorsDataStatus = useSelector(
    (state) => state.sensorsStatusForUser.getSensorsStatus.status
  );
  const getAlertsDataStatus = useSelector(
    (state) => state.alertsForUser.getAlertsForUser.status
  );
  const getVentilationDataStatus = useSelector(
    (state) => state.ventilationDataForUser.getVentilationData.status
  );
  const getAverageDataStatus = useSelector(
    (state) => state.averageDataForUser.getAverageData.status
  );
  const getTempAndHumidDataStatus = useSelector(
    (state) => state.tempAndHumidForUser.getTempAndHumidData.status
  );
  const getPHIForUserStatus = useSelector(
    (state) => state.PHIForUser.getPHIData.status
  );
  const getAQIForUserStatus = useSelector(
    (state) => state.AQIForUser.getAQIData.status
  );
  const getGrowthRateStatus = useSelector(
    (state) => state.growthRateForUser.getGrowthRateForUser.status
  );
  const getDeathRateStatus = useSelector(
    (state) => state.deathRateForUser.getDeathRateForUser.status
  );

  useEffect(() => {
    const isDownloading =
      getSensorsDataStatus === "loading" ||
      getAlertsDataStatus === "loading" ||
      getVentilationDataStatus === "loading" ||
      getAverageDataStatus === "loading" ||
      getTempAndHumidDataStatus === "loading" ||
      getPHIForUserStatus === "loading" ||
      getAQIForUserStatus === "loading" ||
      getGrowthRateStatus === "loading" ||
      getDeathRateStatus === "loading";

    setIsDownloading(isDownloading);
  }, [
    getSensorsDataStatus,
    getAlertsDataStatus,
    getVentilationDataStatus,
    getAverageDataStatus,
    getTempAndHumidDataStatus,
    getPHIForUserStatus,
    getAQIForUserStatus,
    getGrowthRateStatus,
    getDeathRateStatus,
  ]);

  return isDownloading;
}

export default useIsDownloading;
