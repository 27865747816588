import { makeRequest } from "../makeRequest";

const URL = "/Device";

export const setDeviceArea = (coopId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/setDeviceArea`,
    data
  });
};

export const getDevicesArea = async (coopId, data) => {
  const res = await makeRequest({
    method: "GET",
    url: `${URL}/${coopId}/getDevicesArea`
  });

  return res?.data?.data;
};


