import axios from "axios";

let store;
export const injectStore = (_store) => {
  store = _store;
};
const axiosInstance = axios.create({});
// const API_ENDPOINT = "http://localhost:3002/api";
const API_ENDPOINT = "https://dev-api.sinornis.com/api";


const urlsSkipAuth = [`${API_ENDPOINT}/Account/Login`];

axiosInstance.interceptors.request.use(async (config) => {
  if (urlsSkipAuth.includes(config.url)) {
    return config;
  }

  let accessToken = store.getState().auth.authData.accessToken;
  if (config.url === `${API_ENDPOINT}/Account/Refresh`) {
    accessToken = localStorage.getItem("SALUS_ACCESS_TOKEN");
  } else {
    accessToken = store.getState().auth.authData.accessToken;
  }

  if (accessToken) {
    const Authorization = `${accessToken}`;
    config.headers = {
      Authorization,
    };
  }

  return config;
});

export const makeRequest = (config) => {
  config.url = `${API_ENDPOINT}${config.url}`;
  let accessToken = localStorage.getItem("SALUS_ACCESS_TOKEN");
  
  if (accessToken) {
    const Authorization = `${accessToken}`;
    config.headers = {
      ...config.headers,
      Authorization,
    };
  }

  return axiosInstance(config);
};

/* axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      !!store.getState().auth.authData.accessToken &&
      error.request.url !== `${API_ENDPOINT}/logout`
    ) {
      store.dispatch(logoutUser());
    }

    throw error;
  }
); */
