import styles from "./Filters.module.scss";
import {ReactComponent as SettingsIcon} from '../../../../assets/images/settings.svg';
import {ReactComponent as RelaodIcon} from '../../../../assets/images/reload.svg';
import {ReactComponent as CompleteIcon} from '../../../../assets/images/comlite.svg';
import {ReactComponent as FalseAlarmIcon} from '../../../../assets/images/false_alarm.svg';
import {ReactComponent as PandingIcon} from '../../../../assets/images/panding.svg';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Select from "../select/Select";
import Checkbox from "../../UI/Checkbox/Checkbox";
dayjs.extend(utc);

function Filter({listAlert}) {
  const { t } = useTranslation();
  const [countsAlerts, setCountsAlerts] = useState({
    high: 0,
    medium: 0,
    low: 0
  });

  useEffect(() => {
    setCountsAlerts((old) => {
      let obj = {
        high: 0,
        medium: 0,
        low: 0
      }
      if(listAlert.length) {
        for(let item of listAlert) {
          switch(item.priority) {
            case 2:
              obj.high++;
              break;
            case 1:
              obj.medium++;
              break;
            default:
              obj.low++;
              break;
          }
        }  
      } 
      
      return obj;
    });
  }, [listAlert]);

  return (
    <div className={styles.componentHeader}>
      <div className={styles.headerBlock}>
        <h3>{t("SENSORS.SENSORS")}</h3>
        <div className={styles.block}>
          <SettingsIcon />    
        </div>
      </div>

      <div className={styles.componentHeaderBlock}>
        <div className={classNames(styles.buttonFilterPriority, styles.high)}>
          {t("SENSORS.HIGH")} ({countsAlerts.high})
        </div>
        <div className={classNames(styles.buttonFilterPriority, styles.medium)}>
          {t("SENSORS.MEDIUM")} ({countsAlerts.medium})
        </div>
        <div className={classNames(styles.buttonFilterPriority, styles.low)}>
          {t("SENSORS.LOW")} ({countsAlerts.low})
        </div>
      </div>

      <div className={styles.sortFilterHeaderBlock}>
        <div className={styles.sortBlock}>
          <label>{t("SENSORS.SORT_BY")}:</label>  
          <Select list={[
            {_id: "1", name: "1", vlaue: "1"}, 
            {_id: "2", name: "2", vlaue: "2"}]}
            name={t("SENSORS.SEVERITY")}
            />
            <Select list={[
            {_id: "1", name: "1", vlaue: "1"}, 
            {_id: "2", name: "2", vlaue: "2"}]}
            name={t("SENSORS.STATUS")}
            />
            <Select list={[
            {_id: "1", name: "1", vlaue: "1"}, 
            {_id: "2", name: "2", vlaue: "2"}]}
            name={t("SENSORS.CATEGORY")}
            />
            <RelaodIcon className={styles.reloadIcon}/>
        </div>
        <div className={styles.sortBlock}>
          <label>{t("SENSORS.FILTER_BY")}:</label> 
          <Select list={[
            {_id: "1", name: "1", vlaue: "1"}, 
            {_id: "2", name: "2", vlaue: "2"}]}
            name={t("SENSORS.SEVERITY")}
            />
        </div>
        <div className={styles.sortBlock}>
          <Checkbox className={styles.checkbox}/>
          <div className={styles.statusBlock}>
            <CompleteIcon /> {t("SENSORS.COMPLETE")}
          </div>
          <div className={styles.statusBlock}>
            <FalseAlarmIcon /> {t("SENSORS.FALSE_ALARM")}
          </div>
          <div className={styles.statusBlock}>
            <PandingIcon /> {t("SENSORS.PANDING")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
