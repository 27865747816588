import { makeRequest } from "../makeRequest";

const URL = "/Admin/Farms";

export const getFarmById = (farmId) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${farmId}`,
  });
};

export const deleteFarmById = (farmId) => {
  return makeRequest({
    method: "DELETE",
    url: `${URL}/${farmId}`,
  });
};

export const createFarm = (data) => {
  return makeRequest({
    method: "POST",
    url: URL,
    data,
  });
};

export const updateFarmName = (farmId, data) => {
  return makeRequest({
    method: "PUT",
    url: `${URL}/${farmId}`,
    data,
  });
};

export const updateFarmUsers = (farmId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${farmId}/Users`,
    data,
  });
};

export const createCoopForFarm = (farmId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${farmId}/Coops`,
    data,
  });
};
