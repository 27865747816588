import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../../utils/sharedExtraReducers";
// import { changeUpdatingHumTempAverageStatus } from "../../updatingSlice";

const initialState = {
  getAverageData: {
    averageData: null,
    samples: null,
    status: null,
    error: null,
  },
};

// export const getAverageDataThunk = createAsyncThunk(
//   "averageDataForUser/getAverageData",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { cycleId, data, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingHumTempAverageStatus("started"));
//       const response = await api.dashboard.getAverageData(cycleId, data);
//       dispatch(addAverageDataToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingHumTempAverageStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingHumTempAverageStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const averageDataForUserSlice = createSlice({
  name: "averageDataForUser",
  initialState,
  reducers: {
    // addAverageDataToState(state, action) {
    //   if (!action.payload) {
    //     state.getAverageData.averageData = action.payload;
    //     state.getAverageData.samples = action.payload;
    //   } else {
    //     const { samples, ...averageData } = action.payload;
    //     const normalizedSamples = samples.reduce(
    //       (accum, item) => {
    //         if(item.field === "humidity") {
    //           accum.averageHumid.push([
    //             item.time,
    //             item.value,
    //           ]);
    //         }
    //         if(item.field === "temperature") {
    //           accum.averageTemp.push([
    //             item.time,
    //             item.value,
    //           ]);
    //         }
    //         return accum;
    //       },
    //       {
    //         averageTemp: [],
    //         averageHumid: [],
    //       }
    //     );

    //     state.getAverageData.averageData = averageData;
    //     state.getAverageData.samples = normalizedSamples;
    //   }
    // },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       getAverageDataThunk.pending,
  //       extraReducers(pending, "getAverageData")
  //     )
  //     .addCase(
  //       getAverageDataThunk.rejected,
  //       extraReducers(rejected, "getAverageData")
  //     )
  //     .addCase(
  //       getAverageDataThunk.fulfilled,
  //       extraReducers(fulfilled, "getAverageData")
  //     );
  // },
});

export const { addAverageDataToState } = averageDataForUserSlice.actions;
export default averageDataForUserSlice.reducer;
