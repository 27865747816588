export function addParametersToUrl(items) {
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${items.join('&')}`;
    window.history.pushState({path:newurl},'',newurl);
}

export function cleanParametersFromUrl() {
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.pushState({path:newurl},'',newurl);
}

export function getUrlParameters() {
    const queryString = window.location.search;
    const items =
        queryString.replace('?', '').split('&');
    const obj = {};
    items.forEach(elem => {
        const urlParameters = elem.split('=');
        if (urlParameters[0]) {
            obj[urlParameters[0]] = urlParameters[1];
        }
    });
    return obj;
}
