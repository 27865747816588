export const translationsEN = {
  LOGINPAGE: {
    WELCOME: "Welcome to Sinornis",
    USERNAME: "User Name EN",
    TYPENAME: "Type Name",
    PASSWORD: "Password",
    TYPE: "Type",
    LOG_IN: "Log In",
  },

  HEADER: {
    LOG_OUT: "Log out",
    TODAY: "Today",
    FARM_NAME: "Farm",
    COOP_NAME: "Coop",
    GROWTH_NAME: "Growth Name",
    CYCLE: "Cycle",
    GROWTH_DAY: "Growth Day",
    START: "Start Cycle",
    STOP: "Stop Cycle",
    GROWING_CYCLE: "Growing Cycle",
    FARMS: "Farms",
    ALERTS: "Alerts",
    USERS: "Users",
  },

  NEW_GROWINGCYCLE_DIALOG: {
    HEADER: "New Growing Cycle",
    CYCLE_NAME: "Cycle Name",
    NAME_PLACEHOLDER: "Type Name",
    CHICKENS_NUMBER: "Chickens Number",
    NUMBER_PLACEHOLDER: "Type Number",
    GROWTH_DAY: "Growth Day (1-45)",
    TOTAL_GROWTH_DAY: "Total Growth Days",
    SAVE_BUTTON: "Save",
  },

  CONFIRMATION_DIALOG: {
    QUESTION: "Do you really want to stop the active cycle?",
    YES: "Yes",
    NO: "No",
  },

  SENSORS_DATA: {
    SENSORS: "Sensors",
    ON: "On",
    OFF: "Off",
    COOLING_PAD: "Cooling Ped",
    AREA: "Area",
  },

  SELECT: {
    CHOOSE: "Choose",
  },

  SENSORS: {
    COMPLETE: "Complete",
    FALSE_ALARM: "False Alarm",
    PANDING: "Pending",
    SEVERITY: "Severity",
    STATUS: "Status",
    CATEGORY: "Category",
    SENSORS: "Alerts",
    CRITICAL_LEVEL: "Critical Level",
    HIGH: "High",
    MEDIUM: "Medium",
    LOW: "Low",
    TODAY: "Today",
    FILTER_BY: "Filter by",
    SORT_BY: "Sort by",
    YESTERDAY: "Yesterday",
    NOMESSAGES: "There are no any messages yet",
  },

  VENTILATION: {
    HEADER: "Ventilation",
    CLIMATE: "Climate",
    FIRST_SUBHEADER: "Heat Stress By Area",
    NOW_BUTTON: "Now",
    AVERAGE_BUTTON_1: "Average 24-Hr",
    HIGH: "High",
    LOW: "Low",
    NORMAL: "Normal",
    CRITICAL: "Critical",
    AREA: "Area",
    SECOND_SUBHEADER: "Indices 24-Hr",
    AVERAGE_BUTTON_2: "Average",
    SENSORS_BUTTON: "By Sensors",
    TEMP_BUTTON: "TEMP.",
    RH_BUTTON: "RH",
    MAX: "Max",
    MIN: "Min",
    THIRD_SUBHEADER: "Moisture Removal",
    RUN_CYCLE: "Run Cycle (sec)",
    ACTIVE_FANS: "Active Fans,",
    AIR_FLOW: "Air Flow(cmh)",
    FOURTH_SUBHEADER1: "Ventilation",
    FOURTH_SUBHEADER2: "Recommendations",
    CALCULATE_BUTTON: "Calculate",
    TEMP_OUTSIDE: "Temp Outside (C°)",
    CYCLE: "Cycle (%)",
    CYCLE_RUN_TIME: "Cycle Run Time (sec)",
    AIR: `Air (m3)/1Kg`,
  },

  EFFICIENCY: {
    HEADER: "Efficiency",
    FIRST_SUBHEADER: "Poultry Health",
    NOW_BUTTON: "Now",
    AVERAGE_BUTTON_2: "Weekly Average",
    AQI_LEVEL: "AQI Level",
    HAZARDOUS: "Hazardous",
    UNHEALTHY_MAX: "Unhealthy Max",
    UNHEALTHY: "Unhealthy",
    UNHEALTHY_MIN: "Unhealthy Min",
    GOOD: "Good",
    HOURS_24: "24-Hr",
  },

  DEATH_RATE: {
    HEADER: "Death Rate",
    PLANNED: "Planned",
    CURRENT: "Current",
    NEW_EDIT: "New / Edit",
    CHOOSE_DATE: "Choose Date",
    TYPE_NUMBER: "Type Number",
    ADD_BUTTON: "Add",
    SECOND_HEADER: "Growth Rate",
    GROWTH_DAY: "Growth Day",
    UPDATE_BUTTON: "Update",
    TYPE_WEIGHT: "Type weight",
    WEIGHT: "Weight",
    DAY: "Day",
  },

  USER_TABLE: {
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email",
    ROLE: "Roles",
    FIRST_NAME_LABEL: "First Name:",
    FIRST_NAME_PLACEHOLDER: "Type first name",
    LAST_NAME_LABEL: "Last Name:",
    LAST_NAME_PLACEHOLDER: "Type last name",
    FIRST_NAME_ERROR_1: "First name is required",
    FIRST_NAME_ERROR_2: "Minimum field length is 2 characters",
    LAST_NAME_ERROR_1: "Last name is required",
    LAST_NAME_ERROR_2: "Minimum field length is 2 characters",
    USER_ROLE_PLACEHOLDER: "Choose user role",
    USER_ROLE_ERROR: "Role is required",
    USER_ROLE_LABEL: "User role:",
    SELECT_FARMS_LABEL: "List of farms:",
    ALARM_MESSAGE:
      "Do not touch these fields unless you want to change the user's password",
    PASSWORD_LABEL: "User password:",
    PASSWORD_PLACEHOLDER: "Type new password",
    PASSWORD_ERROR: "Min length is 6 characters",
    MATCH_PASSWORD_LABEL: "Confirm password:",
    MATCH_PASSWORD_ERROR: "Doesn't match with password",
    MATCH_PASSWORD_PLACEHOLDER: "Confirm new password",
    SAVE_BUTTON: "Save",
    RESET_BUTTON: "Reset",
    ADD_BUTTON: "Add user",
    TOASTER_UPDATE_ROLE: "Role was successfuly updated",
    TOASTER_UPDATE_USER: "User was successfuly updated",
  },

  CREATE_USER_FORM: {
    FIRST_NAME_LABEL: "First name:",
    FIRST_NAME_PLACEHOLDER: "Type first name",
    LAST_NAME_LABEL: "Last name:",
    LAST_NAME_PLACEHOLDER: "Type last name",
    PASSWORD_LABEL: "Password:",
    PASSWORD_PLACEHOLDER: "Type password",
    EMAIL_LABEL: "Email:",
    EMAIL_PLACEHOLDER: "Type email",
    INPUT_ERROR_1: "First name is required",
    INPUT_ERROR_2: "Minimum field length is 2 characters",
    INPUT_ERROR_3: "Last name is required",
    INPUT_ERROR_4: "Email is required",
    INPUT_ERROR_5: "Email must have correct form",
    INPUT_ERROR_6: "Password is required",
    INPUT_ERROR_7: "Min length is 6 characters",
    INPUT_ERROR_8: "Password must contain one number at least",
    INPUT_ERROR_9:
      "Password must contain one special symbol at least (!@#$%^&*?+-)",
    INPUT_ERROR_9_1: "Password must contain one special symbol at least",
    INPUT_ERROR_10: "Password must contain one letter in lowercase at least",
    INPUT_ERROR_11: "Password must contain one letter in uppercase at least",
    TOASTER_USER_CREATE: "User was created successfuly",
  },

  ALERTS_TABLE: {
    TITLE: "Title",
    PRIORITY: "Priority",
    IS_ACTIVE: "Is Active",
    MESSAGE: "Message",
    PARAMS: "Parameters",
    PARAM_NAME: "Parameter",
    PARAM_VALUE: "Value",
  },

  ALERTS_FORM: {
    NAME: "Alert Name",
    NAME_PLACEHOLDER: "Type name",
    ACTIVE: "Is Active",
    PRIORITY: "Priority:",
    LOW: "Low",
    NORMAL: "Normal",
    HIGH: "High",
    MESSAGE: "Alert Message",
    MESSAGE_PLACEHOLDER: "Type message",
    MESSAGE_LANG_ENG: "In English:",
    MESSAGE_LANG_HEB: "In Hebrew:",
    RECOMENDATION: "Alert Recomendation",
    PARAMS: "Parameters",
    PARAMS_NAME_PLACEHOLDER: "Parameter name",
    PARAMS_NAME_LABEL: "Name:",
    PARAMS_VALUE_LABEL: "Value:",
    PARAMS_VALUE_PLACEHOLDER: "Value",
    BUTTON_CREATE: "Create",
    BUTTON_UPDATE: "Update",
    BUTTON_RESET: "Reset",
  },

  FARM_TABLE: {
    TOASTER_CREATE: "Farm was successfully created!",
    TOASTER_DELETE: "Farm was successfully deleted!",
    ADD_FARM_BUTTON: "Add farm",
    TABLE_HEADER: "Farm Name",
    CONFIRM_MESSAGE: "Do you realy want to delete farm",
  },

  FARM_FORM: {
    HEADER: "Name of new farm:",
    PLACEHOLDER: "Type farm name",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
  },

  UPDATE_FARM_FORM: {
    TOASTER_COOP_CREATED: "Coop was successfully created!",
    TOASTER_FARM_UPDATED: "Farm name was successfully updated!",
    TOASTER_FARM_USERLIST_UPDATED:
      "User list of farm was successfully updated!",
    FARM_NAME_INPUT_LABEL: "Farm name:",
    FARM_NAME_INPUT_PLACEHOLDER: "Type name",
    SAVE_BUTTON: "Save",
    RESET_BUTTON: "Reset",
    FARM_USERLIST_INPUT_LABEL: "List of users:",
    ADD_COOP_BUTTON: "Add coop",
    GO_BACK_BUTTON: "Go back",
    TABLE_COOP_NAME_HEADER: "Coop Name",
    FARM_NAME_INPUT_ERROR1: "Farm name is required",
    FARM_NAME_INPUT_ERROR2: "Minimum field length is 2 characters",
  },

  COOP_FORM: {
    NAME_INPUT_LABEL: "Name of new coop:",
    NAME_INPUT_PLACEHOLDER: "Type farm name",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
  },

  UPDATE_COOP_FORM: {
    TOASTER_COOP_UPDATE:
      "Coop name with coordinates were successfully updated!",
    TOASTER_COOP_SENSORS_UPDATE: "Coop sensors were successfully updated!",
    INPUT_COOP_NAME_LABEL: "Coop name:",
    INPUT_COOP_NAME_PLACEHOLDER: "Type name",
    INPUT_COOP_LAT_LABEL: "Lat:",
    INPUT_COOP_LAT_PLACEHOLDER: "Type Lat",
    INPUT_COOP_LON_LABEL: "Lon:",
    INPUT_COOP_LON_PLACEHOLDER: "Type Lon",
    SAVE_BUTTON: "Save",
    RESET_BUTTON: "Reset",
    SENSORS_ERROR_MESSAGE: "The value must be unique",
    SENSORS_HEADER: "Sensors",
    SENSOR_INPUT_LABEL: "Position",
    SENSOR_INPUT_PLACEHOLDER: "Type sensor ID",
    GO_BACK_BUTTON: "Go back",
    INPUT_COOP_NAME_ERROR1: "Coop name is required",
    INPUT_COOP_NAME_ERROR2: "Minimum field length is 2 characters",
  },

  CHART: {
    ARIA: "Area",
    PLANNED: "Planned",
    CURRENT: "Current",
  },
};
