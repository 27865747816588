import { makeRequest } from "../makeRequest";

const URL = "/Alerts";

export const getAlerts = () => {
  return makeRequest({
    method: "GET",
    url: `${URL}`,
  });
};

export const updateAlert = (data) => {
  return makeRequest({
    method: "PUT",
    url: `${URL}/${data._id}`,
    data,
  });
};

export const createAlert = (data) => {
  return makeRequest({
    method: "POST",
    url: URL,
    data,
  });
};
