import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { getFarmsThunk } from "../farmsForUserSlice";
import {
  extraReducers,
  fulfilled,
  pending,
  rejected,
} from "../../utils/sharedExtraReducers";

const initialState = {
  updatingFarm: null,
  getFarmsForAdmin: {
    response: null,
    status: null,
    error: null,
  },
  createFarm: {
    status: null,
    error: null,
  },
  deleteFarm: {
    status: null,
    error: null,
  },
  updateFarmName: {
    status: null,
    error: null,
  },
  updateFarmUsers: {
    status: null,
    error: null,
  },
  createCoopForFarm: {
    status: null,
    error: null,
  },
};

export const getFarmByIdThunk = createAsyncThunk(
  "farmsData/getFarmById",
  async function (farmId, { rejectWithValue, dispatch }) {
    try {
      const response = await api.adminFarms.getFarmById(farmId);
      dispatch(addUpdatingFarmToState(response.data));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createFarmThunk = createAsyncThunk(
  "farmsData/createFarm",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      await api.adminFarms.createFarm(data);
      dispatch(getFarmsThunk());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteFarmByIdThunk = createAsyncThunk(
  "farmsData/deleteFarmById",
  async function (farmId, { rejectWithValue, dispatch }) {
    try {
      await api.adminFarms.deleteFarmById(farmId);
      dispatch(getFarmsThunk());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateFarmNameThunk = createAsyncThunk(
  "farmsData/updateFarmName",
  async function (dataForUpdate, { rejectWithValue, dispatch }) {
    try {
      const { farmId, ...data } = dataForUpdate;
      await api.adminFarms.updateFarmName(farmId, data);
      dispatch(getFarmByIdThunk(farmId));
      dispatch(getFarmsThunk());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateFarmUsersThunk = createAsyncThunk(
  "farmsData/updateFarmUsers",
  async function (dataForUpdate, { rejectWithValue, dispatch }) {
    try {
      const { farmId, ...data } = dataForUpdate;
      await api.adminFarms.updateFarmUsers(farmId, data);
      dispatch(getFarmByIdThunk(farmId));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createCoopForFarmThunk = createAsyncThunk(
  "farmsData/createCoopForFarm",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      const { farmId, ...coopData } = data;
      await api.adminFarms.createCoopForFarm(farmId, coopData);
      dispatch(getFarmByIdThunk(farmId));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const farmsDataSlice = createSlice({
  name: "farmsData",
  initialState,
  reducers: {
    addUpdatingFarmToState(state, action) {
      state.updatingFarm = action.payload;
    },
    addGettedFarmsForAdminToState(state, action) {
      state.getFarmsForAdmin.response = action.payload;
    },
    updateUpdatingFarm(state, action) {
      state.updatingFarm = action.payload;
    },
    setCreationStatusToNull(state) {
      state.createFarm.status = null;
    },
    setDeletionStatusToNull(state) {
      state.deleteFarm.status = null;
    },
    setUpdatingNameStatusToNull(state) {
      state.updateFarmName.status = null;
    },
    setUpdatingFarmUsersStatusToNull(state) {
      state.updateFarmUsers.status = null;
    },
    setCreatingCoopStatusToNull(state) {
      state.createCoopForFarm.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFarmThunk.pending, extraReducers(pending, "createFarm"))
      .addCase(createFarmThunk.rejected, extraReducers(rejected, "createFarm"))
      .addCase(
        createFarmThunk.fulfilled,
        extraReducers(fulfilled, "createFarm")
      )

      .addCase(
        deleteFarmByIdThunk.pending,
        extraReducers(pending, "deleteFarm")
      )
      .addCase(
        deleteFarmByIdThunk.rejected,
        extraReducers(rejected, "deleteFarm")
      )
      .addCase(
        deleteFarmByIdThunk.fulfilled,
        extraReducers(fulfilled, "deleteFarm")
      )

      .addCase(
        updateFarmNameThunk.pending,
        extraReducers(pending, "updateFarmName")
      )
      .addCase(
        updateFarmNameThunk.rejected,
        extraReducers(rejected, "updateFarmName")
      )
      .addCase(
        updateFarmNameThunk.fulfilled,
        extraReducers(fulfilled, "updateFarmName")
      )

      .addCase(
        updateFarmUsersThunk.pending,
        extraReducers(pending, "updateFarmUsers")
      )
      .addCase(
        updateFarmUsersThunk.rejected,
        extraReducers(rejected, "updateFarmUsers")
      )
      .addCase(
        updateFarmUsersThunk.fulfilled,
        extraReducers(fulfilled, "updateFarmUsers")
      )

      .addCase(
        createCoopForFarmThunk.pending,
        extraReducers(pending, "createCoopForFarm")
      )
      .addCase(
        createCoopForFarmThunk.rejected,
        extraReducers(rejected, "createCoopForFarm")
      )
      .addCase(
        createCoopForFarmThunk.fulfilled,
        extraReducers(fulfilled, "createCoopForFarm")
      );
  },
});

export const {
  addUpdatingFarmToState,
  addGettedFarmsForAdminToState,
  updateUpdatingFarm,
  setCreationStatusToNull,
  setDeletionStatusToNull,
  setUpdatingNameStatusToNull,
  setUpdatingFarmUsersStatusToNull,
  setCreatingCoopStatusToNull,
} = farmsDataSlice.actions;
export default farmsDataSlice.reducer;
