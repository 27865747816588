import GrowthChart from "./growthChart/growthChart";
import styles from "./GrowthRate.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// import UpdateFormGrowthRate from "./updateFormGrowthRate/UpdateFormGrowthRate";
import GrowthDayInput from "../growthDayInput/GrowthDayInput";
import useIsDownloading from "../../hooks/useIsDownloading";

function GrowthRate() {
  const { i18n, t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.componentHeader}>
        <h3>{t("DEATH_RATE.SECOND_HEADER")}</h3>
        <div className={styles.headerButtons}>
          <div className={styles.growDay}>
            <label>{t("DEATH_RATE.GROWTH_DAY")}</label>
            <GrowthDayInput isDownloading={useIsDownloading} />
          </div>
        </div>
      </div>
      <div className={styles.mainContent}>
        {/* <UpdateFormGrowthRate /> */}
        <div
          className={classNames(styles.graphSide, {
            [styles.paddingForRtl]: i18n.language === "he",
          })}
        >
          <div className={styles.graph}>
            <GrowthChart />
          </div>
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={styles.greenCircle}></div>
              <span>{t("DEATH_RATE.PLANNED")}</span>
            </div>
            <div className={styles.item}>
              <div className={styles.blueCircle}></div>
              <span>{t("DEATH_RATE.CURRENT")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrowthRate;
