import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import "./style.scss";

function LayoutDefault({component, headerBar}) {
  return (
    <div className="container">
      <Header headerBar={headerBar}/>
      <div className="content">
        <Menu />
        <div className="row">
          {component}
        </div>
      </div>
    </div>
  );
}

export default LayoutDefault;
