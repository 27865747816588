import styles from './CommonButton.module.scss'

function CommonButton({
  buttonText,
  handleClick,
  buttonStyles,
  buttonType = 'button'
}) {

  const nothingDo = () => {return}

  return (
    <button
    type={buttonType}
    onClick={ handleClick ? handleClick : nothingDo} 
    className={styles.commonButton}
    style={buttonStyles}
  >
    {buttonText}
  </button>
  )
}

export default CommonButton