import style from "./style.module.scss";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OutsideAlerter from '../../../hooks/useOutsideAlerter';

function Select({list = [], activeSelect = "", handleSelect = () => {}}) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState('Choose');
    
    useEffect(() => {
        setSelected(activeSelect);
    }, [activeSelect])

    return (
        <>
            <OutsideAlerter handler={()=>{setOpen(false)}}>
            <div className={[style.select, (open && style.open) || '' ].join(' ')}>
                <div className={style.selectName} onClick={() => {
                    setOpen(!open);
                }}>
                    {open ? 'Choose': selected.name}
                    <KeyboardArrowDownIcon className={style.downIcon}/></div>
                <div className={style.selectListRow}
                onClick={() => {
                    setOpen(!open);
                }}>
                    <div className={style.selectList}>
                        {/* {JSON.stringify(list)} */}
                        {list.map((item) => 
                            <div key={item._id} className={style.selectListItem} onClick={() => handleSelect(item)}>
                                {item.name}
                            </div>)
                        }
                    </div>
                </div>
            </div>
            </OutsideAlerter>
        </>
        
    );
}

export default Select;
