import styles from './Input.module.scss'
function InputModal({name="", placeholder="", onClick = () => {}, type="text", defaultValue = ''}) {


  return (  
    <div className={styles.inputContent}>
      <label>
        {name}
      </label>

      <input 
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        onChange={(e) => onClick(e.target.value)}
      />
    </div>
  );
}

export default InputModal;