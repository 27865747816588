import { useState } from "react";
// import LineChart from "./lineChart/LineChart";
import styles from "./TemperatureHumidityData.module.scss";
import classNames from "classnames";
import AverageData from "./averageData/AverageData";
// import TemperatureIcon from "./icons/TemperatureIcon";
// import MoistIcon from "./icons/MoistIcon";
// import { useDispatch, useSelector } from "react-redux";
// import { CircularProgress } from "@mui/material";
// import {
//   addTempAndHumidDataToState,
//   tempAndHumidForUserThunk,
// } from "../../../store/dashboardSlices/ventilationSlices/tempAndHumidForUserSlice";
import { useTranslation } from "react-i18next";
// import useUpdate from "../../../hooks/useUpdate";
// import { api } from "../../../api/api";

function TemperatureHumidityData() {
  const { t } = useTranslation();

  // const dispatch = useDispatch();

  const [isAverage, setIsAverage] = useState(true);
  // const [averageData, setAverageData] = useState(null);
  // const [loader, setLoader] = useState(false);
  // const [isTemp, setIsTemp] = useState(true);

  // const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  // const tempAndHumidData = useSelector(
  //   (state) => state.tempAndHumidForUser.getTempAndHumidData.tempAndHumidData
  // );
  // const tempData = tempAndHumidData
  //   ? {
  //       dangerHigh: tempAndHumidData.tempDangerHigh,
  //       dangerLow: tempAndHumidData.tempDangerLow,
  //       poorHigh: tempAndHumidData.tempPoorHigh,
  //       poorLow: tempAndHumidData.tempPoorLow,
  //     }
  //   : null;
  // const humidData = tempAndHumidData
  //   ? {
  //       dangerHigh: tempAndHumidData.humidDangerHigh,
  //       dangerLow: tempAndHumidData.humidDangerLow,
  //       poorHigh: tempAndHumidData.humidPoorHigh,
  //       poorLow: tempAndHumidData.humidPoorLow,
  //     }
  //   : null;
  // const tempGridValues = [5, 10, 15, 20, 25, 30, 35, 40];
  // const humidGridValues = [10, 20, 30, 40, 50, 60, 70, 80, 90];
  // const samples = useSelector(
  //   (state) => state.tempAndHumidForUser.getTempAndHumidData.samples
  // );
  // const downloadingStatus = useSelector(
  //   (state) => state.tempAndHumidForUser.getTempAndHumidData.status
  // );
  // const updatingStatus = useSelector((state) => state.updating.humTemp);

  // useEffect(() => {
  //   if (!chosenCycle?.settedGrowthDay) {
  //     dispatch(addTempAndHumidDataToState(null));
  //   }

  //   if (chosenCycle?._id && chosenCycle?.settedGrowthDay) {
  //     makeRequest();
  //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chosenCycle]);

  // const makeRequest = (isNow, isUpdating) => {
  //   if(!chosenCycle._id) return;
  //   setLoader(true);
  //   api.dashboard.getTempAndHumidData(chosenCycle._id, chosenCycle.settedGrowthDay).then(data => {
  //     setAverageData(data);
  //     setLoader(false);
  //   });
    // const payload = {
      
    //   cycleId: chosenCycle._id,
    //   data: chosenCycle.settedGrowthDay,
    //   isUpdating,
    // };

    // dispatch(tempAndHumidForUserThunk(payload));
  // };

  // useUpdate(makeRequest, 1);

  // const defineGridLines = (data, gridValues) => {
  //   const collorsArray = gridValues.map((item) => {
  //     return item <= data.dangerLow || item >= data.dangerHigh
  //       ? "#F24E1E" /* red */
  //       : (item > data.dangerLow && item <= data.poorLow) ||
  //         (item >= data.poorHigh && item < data.dangerHigh)
  //       ? "#FFB23A" /* yellow */
  //       : item > data.poorLow && item < data.poorHigh
  //       ? "#0ACF83" /* green */
  //       : "grey";
  //   });
  //   return collorsArray;
  // };

  return (
    <div className={styles.container}>
      <div className={styles.componentHeader}>
        <h3>{t("VENTILATION.CLIMATE")}</h3>

        <div className={styles.headerButtons}>
          <button
            type="button"
            onClick={() => setIsAverage(true)}
            className={classNames(styles.headerButton, {
              [styles.pressedButton]: isAverage,
              [styles.unpressedButton]: !isAverage,
            })}
          >
            {t("VENTILATION.AVERAGE_BUTTON_2")}
          </button>
          <button
            type="button"
            onClick={() => setIsAverage(false)}
            className={classNames(styles.headerButton, {
              [styles.pressedButton]: !isAverage,
              [styles.unpressedButton]: isAverage,
            })}
          >
            {t("VENTILATION.SENSORS_BUTTON")}
          </button>
        </div>

      </div>
      {/* {downloadingStatus === "loading" && updatingStatus !== "started" && ( */}
        {/* <div>
          <CircularProgress />
        </div> */}
      {/* )} */}
      {isAverage && <AverageData/>}
    </div>
  );
}

export default TemperatureHumidityData;
