import React from "react";
import stylesParent from "../style.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// function useOutsideAlerter(ref, callback) {
//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         callback();
//       }
//     }
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [ref]);
// }

function AdminMenu() {
  const adminMenu = [
    { id: 1, t: "HEADER.FARMS", url: "/manage-farms"},
    { id: 2, t: "HEADER.ALERTS", url: "/manage-alerts"},
    { id: 3, t: "HEADER.USERS", url: "/manage-users"}
  ]
  const { t } = useTranslation();

  // const wrapperRef = useRef(null);
  // const navigate = useNavigate();
  // useOutsideAlerter(wrapperRef, handleCloseAdminMenu);

  // const goToSelectedMenu = (url) => {
    // navigate(url);
    // handleCloseAdminMenu();
  // };

  return (
    <div className={stylesParent.listFarms}>
      {adminMenu.map(item => (<div className={[stylesParent.listFarmsItem].join(' ')}
          key={item._id}
      >
          <Link to={item.url}>
            {t(item.t)}
          </Link>
      </div>))}
    </div>
  );
}

export default AdminMenu;
