import {
  getCoopForAdminById,
  updateCoopNameAndCoordinates,
  updateCoopSensors,
} from "./requests/adminCoops";
import {
  getFarmById,
  createFarm,
  deleteFarmById,
  updateFarmName,
  updateFarmUsers,
  createCoopForFarm,
} from "./requests/adminFarms";
import {
  getUsers,
  createUser,
  updateUser,
  updateUserRole,
} from "./requests/adminUsers";
import { createAlert, getAlerts, updateAlert } from "./requests/alerts";
import {
  getCoopById,
  startCycle,
  stopCycle,
  updateDeath,
  updateGrowth,
} from "./requests/coopsForUsers";
import {
  getAlertsForUser,
  getDeathRateForUser,
  getGrowthRateForUser,
  getSensorsStatus,
  getVentilationData,
  getTempAndHumidData,
  getAverageData,
  getAQIData,
  getPHIData,
  getVentilationRecomendation,
} from "./requests/dashboardDataForUsers";
import { getFarms } from "./requests/farmsForUsers";
import { login, getRefreshToken, getUserData } from "./requests/userAuth";

export const api = {
  userAuth: {
    login,
    getRefreshToken,
    getUserData,
  },
  alerts: {
    getAlerts,
    updateAlert,
    createAlert,
  },
  farms: {
    getFarms,
  },
  coops: {
    getCoopById,
    startCycle,
    stopCycle,
    updateDeath,
    updateGrowth,
  },
  dashboard: {
    getAlertsForUser,
    getDeathRateForUser,
    getGrowthRateForUser,
    getSensorsStatus,
    getVentilationData,
    getTempAndHumidData,
    getAverageData,
    getAQIData,
    getPHIData,
    getVentilationRecomendation,
  },
  adminFarms: {
    getFarmById,
    createFarm,
    updateFarmName,
    updateFarmUsers,
    deleteFarmById,
    createCoopForFarm,
  },
  adminCoops: {
    getCoopForAdminById,
    updateCoopNameAndCoordinates,
    updateCoopSensors,
  },
  adminUsers: {
    getUsers,
    createUser,
    updateUser,
    updateUserRole,
  },
};
