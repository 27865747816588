import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../../utils/sharedExtraReducers";
// import { changeUpdatingAQIStatus } from "../../updatingSlice";

const initialState = {
  getAQIData: {
    response: null,
    status: null,
    error: null,
  },
};

// export const getAQIDataThunk = createAsyncThunk(
//   "AQIForUser/getAQIData",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { cycleId, data, isNow, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingAQIStatus("started"));
//       const response = await api.dashboard.getAQIData(cycleId, data, isNow);
//       dispatch(addAQIDataToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingAQIStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingAQIStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const AQIForUserSlice = createSlice({
  name: "AQIForUser",
  initialState,
  reducers: {
    // addAQIDataToState(state, action) {
    //   state.getAQIData.response = action.payload;
    // },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getAQIDataThunk.pending, extraReducers(pending, "getAQIData"))
  //     .addCase(getAQIDataThunk.rejected, extraReducers(rejected, "getAQIData"))
  //     .addCase(
  //       getAQIDataThunk.fulfilled,
  //       extraReducers(fulfilled, "getAQIData")
  //     );
  // },
});

export const { addAQIDataToState } = AQIForUserSlice.actions;
export default AQIForUserSlice.reducer;
