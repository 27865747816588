import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";
import {
  extraReducers,
  pending,
  fulfilled,
  rejected,
} from "../utils/sharedExtraReducers";

const initialState = {
  authData: {
    accessToken: null,
    status: null,
    error: null,
  },
  profileData: {
    userData: {
      firstName: null,
      lastName: null,
      roles: null,
      email: null,
      farms: null,
    },
    status: null,
    error: null,
  },
};

export const loginUserThunk = createAsyncThunk(
  "auth/loginUser",
  async function (data, { rejectWithValue, dispatch }) {
    try {
      const response = await api.userAuth.login(data);
      dispatch(loginUser(response.data.result));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getRefreshTokenThunk = createAsyncThunk(
  "auth/getRefreshToken",
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const response = await api.userAuth.getRefreshToken();
      dispatch(loginUser(response.data));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUserDataThunk = createAsyncThunk(
  "auth/getUserData",
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const response = await api.userAuth.getUserData();
      dispatch(addToStateUserData(response.data));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToStateUserData(state, action) {
      state.profileData.userData = action.payload;
    },
    getTokenFromLocalStorage(state, action) {
      state.authData.accessToken = action.payload.result || action.payload;
    },
    loginUser(state, action) {
      state.authData.accessToken = action.payload.result || action.payload;
      if(action.payload.status === "error") {
        localStorage.removeItem("SALUS_ACCESS_TOKEN")
      } else {
        localStorage.setItem("SALUS_ACCESS_TOKEN", action.payload.result || action.payload);  
      }
    },
    logoutUser(state) {
      state.authData.accessToken = null;
      state.profileData.userData = {
        firstName: null,
        lastName: null,
        roles: null,
        email: null,
        farms: null,
      };
      localStorage.removeItem("SALUS_ACCESS_TOKEN");
      localStorage.removeItem("SALUS_FARM_ID");
      localStorage.removeItem("SALUS_COOP_ID");
      localStorage.removeItem("SALUS_CYCLE_ID");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserThunk.pending, extraReducers(pending, "authData"))
      .addCase(loginUserThunk.fulfilled, extraReducers(fulfilled, "authData"))
      .addCase(loginUserThunk.rejected, extraReducers(rejected, "authData"))
      .addCase(getRefreshTokenThunk.pending, extraReducers(pending, "authData"))
      .addCase(
        getRefreshTokenThunk.fulfilled,
        extraReducers(fulfilled, "authData")
      )
      .addCase(
        getRefreshTokenThunk.rejected,
        extraReducers(rejected, "authData")
      )
      .addCase(
        getUserDataThunk.fulfilled,
        extraReducers(fulfilled, "profileData")
      )
      .addCase(getUserDataThunk.pending, extraReducers(pending, "profileData"))
      .addCase(
        getUserDataThunk.rejected,
        extraReducers(rejected, "profileData")
      );
  },
});

export const {
  getTokenFromLocalStorage,
  logoutUser,
  addToStateUserData,
  loginUser,
} = authSlice.actions;
export default authSlice.reducer;
