import styles from "./Messages.module.scss";
import {ReactComponent as CompleteIcon} from '../../../../assets/images/comlite.svg';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Checkbox from "../../UI/Checkbox/Checkbox";
dayjs.extend(utc);

function Messages({listAlert, type}) {
  const { t, i18n } = useTranslation();
  const noMessageTip = t("SENSORS.NOMESSAGES");

  return (
    <div className={classNames(styles.content, styles[type])}>
      {listAlert?.length ? 
        listAlert.map((message, index, stackOfMessages) => {return (
        <div className={styles.itemContent} key={message._id}>
          <div className={styles.block}>
            <Checkbox id={message._id} className={styles.checkbox}/>
          </div>
          <div className={classNames(styles.block, styles.right)}>
            <div className={styles.top}>
                <div className={styles.statuses}>
                <div className={styles.status}>
                    <CompleteIcon /> {t("SENSORS.COMPLETE")}
                </div>
                <div className={styles.status}>
                    Temp
                </div>
                </div>
                <div className={styles.date}>12/10/2023 | 16:02</div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.desc}>
                <div className={styles.cycle}>3</div>
                {i18n.language === "en"
                    ? message.messsgeEN
                    : message.messsgeHE}
                </div>
                <div className={styles.alertsDetailsButton}>+</div>
            </div>
          </div>
      </div>
      )}
      ) : (
        <div className={styles.noMessages}>{noMessageTip}</div>
      )}
        {
        /* // listAlert.map((message, index, stackOfMessages) => {
        //   const isDateHeaderNeed = defineIsDateHeaderNeed(
        //     message,
        //     index,
        //     stackOfMessages
        //   );
          
        //   return message.recomandationEN &&
        //     message.recomandationHE ? (
        //     <React.Fragment key={message.created_at}>
        //       {isDateHeaderNeed && (
        //         <div className={styles.messageHeader}>
        //           {defineHeader(today, message.created_at)}
        //         </div>
        //       )}
        //       <MessageWithAlert
        //         message={
        //           i18n.language === "en"
        //             ? message.messsgeEN
        //             : message.messsgeHE
        //         }
        //         recommendation={
        //           i18n.language === "en"
        //             ? message.recomandationEN
        //             : message.recomandationHE
        //         }
        //         created={message.created_at}
        //         priority={message.priority}
        //       />
        //     </React.Fragment>
        //   ) : (
        //     <React.Fragment key={message.created}>
        //       {isDateHeaderNeed && (
        //         <div className={styles.messageHeader}>
        //           {defineHeader(today, message.created_at)}
        //         </div>
        //       )}
        //       <Message
        //         message={
        //           i18n.language === "en"
        //             ? message.messsgeEN
        //             : message.messsgeHE
        //         }
        //         created={message.created_at}
        //         priority={message.priority}
        //       />
        //     </React.Fragment>
        //   );
        // }) */}
    </div>
  );
}

export default Messages;
