import style from "./style.module.scss";
import {ReactComponent as Logo} from '../../../assets/images/logo-v2.svg';

function Header({headerBar}) {
  return (
    <>
      <header className={style.container}>
        <Logo className={style.logo}/>
        {headerBar}
      </header>
    </>
  );
}

export default Header;
