import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { getFarmByIdThunk } from "./farmsDataSlice";
import {
  extraReducers,
  fulfilled,
  pending,
  rejected,
} from "../../utils/sharedExtraReducers";

const initialState = {
  updatingCoop: null,
  updateCoopName: {
    status: null,
    error: null,
  },
  updateCoopSensors: {
    status: null,
    error: null,
  },
};

export const getCoopByIdThunk = createAsyncThunk(
  "coopsData/getCoopById",
  async function (coopId, { rejectWithValue, dispatch }) {
    try {
      const response = await api.adminCoops.getCoopForAdminById(coopId);
      const updatingCoop = {
        ...response.data,
        sensors: [...response.data.sensors],
      };
      updatingCoop.sensors.sort((a, b) => a.position - b.position);
      dispatch(addUpdatingCoopToState(updatingCoop));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateCoopNameAndCoordinatesThunk = createAsyncThunk(
  "coopsData/updateCoopNameAndCoordinates",
  async function ({ farmId, coopId, ...data }, { rejectWithValue, dispatch }) {
    try {
      await api.adminCoops.updateCoopNameAndCoordinates(farmId, coopId, data);
      dispatch(getCoopByIdThunk(coopId));
      dispatch(getFarmByIdThunk(farmId));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateSensorsThunk = createAsyncThunk(
  "coopsData/updateSensors",
  async function ({ coopId, dataArray }, { rejectWithValue, dispatch }) {
    try {
      await api.adminCoops.updateCoopSensors(coopId, dataArray);
      dispatch(getCoopByIdThunk(coopId));
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const coopsDataSlice = createSlice({
  name: "coopsData",
  initialState,
  reducers: {
    addUpdatingCoopToState(state, action) {
      state.updatingCoop = action.payload;
    },
    setUpdateCoopNameStatusToNull(state) {
      state.updateCoopName.status = null;
    },
    setUpdateCoopSensorsStatusToNull(state) {
      state.updateCoopSensors.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        updateCoopNameAndCoordinatesThunk.pending,
        extraReducers(pending, "updateCoopName")
      )
      .addCase(
        updateCoopNameAndCoordinatesThunk.rejected,
        extraReducers(rejected, "updateCoopName")
      )
      .addCase(
        updateCoopNameAndCoordinatesThunk.fulfilled,
        extraReducers(fulfilled, "updateCoopName")
      )

      .addCase(
        updateSensorsThunk.pending,
        extraReducers(pending, "updateCoopSensors")
      )
      .addCase(
        updateSensorsThunk.rejected,
        extraReducers(rejected, "updateCoopSensors")
      )
      .addCase(
        updateSensorsThunk.fulfilled,
        extraReducers(fulfilled, "updateCoopSensors")
      );
  },
});

export const {
  addUpdatingCoopToState,
  setUpdateCoopNameStatusToNull,
  setUpdateCoopSensorsStatusToNull,
} = coopsDataSlice.actions;
export default coopsDataSlice.reducer;
