import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../utils/sharedExtraReducers";
// import { changeUpdatingSensorsStatus } from "../updatingSlice";

const initialState = {
  getSensorsStatus: {
    response: null,
    status: null,
    error: null,
  },
};

// export const getSensorsStatusThunk = createAsyncThunk(
//   "sensorsForUser/getSensorsStatus",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { cycleId, data, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingSensorsStatus("started"));
//       const response = await api.dashboard.getSensorsStatus(cycleId, data);
//       dispatch(addSensorsStatusToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingSensorsStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingSensorsStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const sensorsForUserSlice = createSlice({
  name: "sensorsStatusForUser",
  initialState,
  reducers: {
    // addSensorsStatusToState(state, action) {
    //   state.getSensorsStatus.response = action.payload;
    // },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(
  //       getSensorsStatusThunk.pending,
  //       extraReducers(pending, "getSensorsStatus")
  //     )
  //     .addCase(
  //       getSensorsStatusThunk.rejected,
  //       extraReducers(rejected, "getSensorsStatus")
  //     )
  //     .addCase(
  //       getSensorsStatusThunk.fulfilled,
  //       extraReducers(fulfilled, "getSensorsStatus")
  //     );
  // },
});

// export const { addSensorsStatusToState } = sensorsForUserSlice.actions;
export default sensorsForUserSlice.reducer;
