import style from "./style.module.scss";

function Button({icon, label, orientationIcon = 'right', styleType, onClick = () => {}}) {
    return (
        <div onClick={onClick} className={[
            style.button,
            styleType === ('greenBorder' && style.greenBorder) || '',
            styleType === ('lr' && style.lr) || '',
            ].join(' ')}>
            {(icon && orientationIcon === 'left') && icon}
            {label}
            {(icon && orientationIcon === 'right') && icon}
        </div>
    );
}

export default Button;
