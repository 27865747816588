export const rejected = "REJECTED";
export const pending = "PENDING";
export const fulfilled = "FULFILLED";

export function extraReducers(status, key) {
  switch (status) {
    case "REJECTED":
      return (state, action) => {
        state[key].status = "rejected";
        state[key].error = action.payload;
      };

    case "PENDING":
      return (state) => {
        state[key].status = "loading";
        state[key].error = null;
      };

    case "FULFILLED":
      return (state) => {
        state[key].status = "resolved";
        state[key].error = null;
      };

    default:
      break;
  }
}
