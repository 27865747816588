import styles from "./WindSoundFeels.module.scss";
// import { useTranslation } from "react-i18next";


function WindSoundFeels() {
  // const { i18n, t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.sound}>
        <div className={styles.title}>Sound (24hr)</div>
        <div className={styles.content}>
          <div className={styles.block}>
            <div className={styles.title}>Average</div>
            <div className={styles.value}><span>60</span>db/h</div>
          </div>

          <div className={styles.seporate}></div>

          <div className={styles.block}>
            <div className={styles.title}>Average Change</div>
            <div className={styles.value}><span>+35%</span>db/h</div>
          </div>
        </div>
      </div>

      <div className={styles.windFeels}>
        <div className={styles.content}>
          <div className={styles.title}>Wind Speed</div>
          <div className={styles.block}>
            <div className={styles.value}><span>60</span>m/h</div>
          </div>
        </div>

        <div className={styles.seporate}></div>

        <div className={styles.content}>
          <div className={styles.title}>Feels Like</div>
          <div className={styles.block}>
            <div className={styles.value}><span>75</span>m/h</div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default WindSoundFeels;
