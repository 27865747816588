import { makeRequest } from "../makeRequest";

const URL = "/Admin/Coops";

export const getCoopForAdminById = (coopId) => {
  return makeRequest({
    method: "GET",
    url: `${URL}/${coopId}`,
  });
};

export const updateCoopNameAndCoordinates = (farmId, coopId, data) => {
  return makeRequest({
    method: "PUT",
    url: `/Admin/Farms/${farmId}/Coops/${coopId}`,
    data,
  });
};

export const updateCoopSensors = (coopId, data) => {
  return makeRequest({
    method: "POST",
    url: `${URL}/${coopId}/Sensors`,
    data,
  });
};
