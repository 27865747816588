import { createSlice } from "@reduxjs/toolkit";

// ['started', 'finished']
const initialState = {
  sensors: "finished",
  alerts: "finished",
  heatStress: "finished",
  humTemp: "finished",
  humTempAverage: "finished",
  PHI: "finished",
  AQI: "finished",
  deathRate: "finished",
  growthRate: "finished",
};

const updatingSlice = createSlice({
  name: "updating",
  initialState,
  reducers: {
    changeUpdatingSensorsStatus(state, action) {
      state.sensors = action.payload;
    },
    changeUpdatingAlertsStatus(state, action) {
      state.alerts = action.payload;
    },
    changeUpdatingHeatStressStatus(state, action) {
      state.heatStress = action.payload;
    },
    changeUpdatingHumTempStatus(state, action) {
      state.humTemp = action.payload;
    },
    changeUpdatingHumTempAverageStatus(state, action) {
      state.humTempAverage = action.payload;
    },
    // changeUpdatingPHIStatus(state, action) {
    //   state.PHI = action.payload;
    // },
    changeUpdatingAQIStatus(state, action) {
      state.AQI = action.payload;
    },
    changeUpdatingDeathRateStatus(state, action) {
      state.deathRate = action.payload;
    },
    changeUpdatingGrowthRateStatus(state, action) {
      state.growthRate = action.payload;
    },
  },
});

export const {
  changeUpdatingSensorsStatus,
  changeUpdatingAlertsStatus,
  changeUpdatingHeatStressStatus,
  changeUpdatingHumTempStatus,
  changeUpdatingHumTempAverageStatus,
  // changeUpdatingPHIStatus,
  changeUpdatingAQIStatus,
  changeUpdatingDeathRateStatus,
  changeUpdatingGrowthRateStatus,
} = updatingSlice.actions;
export default updatingSlice.reducer;
