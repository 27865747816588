import style from "./style.module.scss";
import {ReactComponent as DashboardIcon} from '../../../assets/images/dashboard.svg';
import {ReactComponent as SignalIcon} from '../../../assets/images/signal.svg';
import {ReactComponent as CalculateIcon} from '../../../assets/images/calculate.svg';
import {ReactComponent as PieIcon} from '../../../assets/images/pie.svg';
import {ReactComponent as LanguageIcon} from '../../../assets/images/language.svg';
import {ReactComponent as AccountIcon} from '../../../assets/images/account.svg';
import {ReactComponent as LogoutIcon} from '../../../assets/images/exit.svg';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";
import { useState } from "react";
import Button from "../UI/Button/Button";
import { useTranslation } from "react-i18next";
import AdminMenu from "./adminMenu/AdminMenu";
import {useDispatch, useSelector} from "react-redux";
import { logoutUser } from "../../store/authSlice";
import { addGettedFarmsToState } from "../../store/farmsForUserSlice";
import { addChosenCycleToState, addGettedCoopToState, setActiveCycle } from "../../store/coopsForUserSlice";

// import { useNavigate } from "react-router";
// import { useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";

function Menu() {
    const [open, setOpen] = useState(false);
    const [openFarms, setOpenFarms] = useState(false);
    const [openCoop, setOpenCoop] = useState(false);
    const [openAdmin, setOpenAdmin] = useState(false);
    const [selectedFarms, setSelectedFarms] = useState(0);
    // const [selectedCoop, setSelectedCoop] = useState(0);
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const { firstName, lastName, email } = useSelector(
        (state) => state.auth.profileData.userData
    );
    const farms = [
        {
            id: 1,
            name: "Jerusalem"
        },
        {
            id: 2,
            name: "Ramat Yochanan"
        },
        {
            id: 3,
            name: "Ramat Hagolan"
        },
        {
            id: 4,
            name: "Ramat Yochanan"
        }
    ];
    const handleOpenMenu = () => {
        setOpen(!open);
    };

    const handlerSelectFarm = (id) => {
        setSelectedFarms(selectedFarms !== id?id:0);
        setOpenCoop(selectedFarms !== id);
    };

    const changeLanguage = () => {
        setLanguage(language === 'en'? 'he' : 'en');
        i18n.changeLanguage(language === 'en'? 'he' : 'en');
    }

    const handlerOpenSubMenu = (id) => {
        switch (id) {
            case 'farm':
                setOpenAdmin(false);
                setOpenFarms(!openFarms);
                break;
            case 'admin':
                setOpenFarms(false);
                setOpenAdmin(!openAdmin);
                break;
            default:
                setOpenFarms(false);
                setOpenAdmin(false);
                setOpenFarms(false);
                break;

        }
    }

    const onLogout = () => {
        dispatch(logoutUser());
        dispatch(addGettedFarmsToState(null));
        dispatch(addGettedCoopToState(null));
        dispatch(addChosenCycleToState(""));
        dispatch(setActiveCycle(""));
      };

    // const navigate = useNavigate();
    // const dispatch = useDispatch();
  return (
    <div className={[style.menu, open? style.open: ''].join(' ')}>
        <div className={style.mainMenu}>
            
            <ul className={style.list}>
                <div className={style.openMenu} onClick={handleOpenMenu}>
                    {open ? <ArrowBackIcon/> : <ArrowForwardIcon/>}
                </div>
                <li className={style.active}>
                    <Link to="/dashboard" className={style.farm} onClick={() => handlerOpenSubMenu('farm')}>
                        <DashboardIcon />
                        <span className={style.nameItem}>Farms</span>
                    </Link>
                </li>
                <li>
                    <Link to="/data">           
                        <SignalIcon />
                        <span className={style.nameItem}>Charts</span>
                    </Link>
                </li>
                <li>
                    <Link to="/calculator">
                        <CalculateIcon />
                        <span className={style.nameItem}>Ventilation Calculator</span>
                    </Link>
                </li>
                <li>
                    <Link to="/report">
                        <PieIcon />
                        <span className={style.nameItem}>Report</span>
                    </Link>
                </li>
                <li>
                    <Link to="" onClick={changeLanguage}>
                        <LanguageIcon />
                        <span className={style.nameItem}>{language}</span>
                    </Link>
                </li>
            </ul>
            <div className={style.bottomBlock}>
                <div className={style.bottomBlockItem}>
                    <Link to="" className={style.bottomBlockIcon} onClick={() => handlerOpenSubMenu('admin')}>
                        <AccountIcon />
                        <span className={style.nameItem}>
                            {firstName} {lastName}
                            {firstName && lastName && <br />}
                            {email}
                        </span>
                    </Link>
                </div>
                <div className={style.bottomBlockItem}>
                    <Link to="" className={style.bottomBlockIcon} onClick={onLogout}>
                        <LogoutIcon />
                        <span className={style.nameItem}>{t("HEADER.LOG_OUT")}</span>
                    </Link>
                </div>
            </div>
        </div>
        {openAdmin && <AdminMenu/>}
        {openFarms && <div className={style.listFarms} >
            {farms.map((item) => <div key={item._id} className={[style.listFarmsItem, selectedFarms === item._id && style.active].join(' ')}
                onClick={() => handlerSelectFarm(item._id)}
            >
                {item.name}
            </div>)}
            <div className={style.listFarmsButtons}>
                <Button
                    label='Add Farm'
                    icon={<AddIcon />}
                />
            </div>
        </div>}
        {openCoop && <div className={style.listFarms} >
            <div className={[style.listFarmsItem, style.active].join(' ')}>
                {farms.find(item => item._id === selectedFarms).name}
            </div>
            {/* <div className={[style.listFarmsItem, selectedCoop === 1 && style.active].join(' ')}
                onClick={() => setSelectedFarms(1)}
            > */}
            <div className={[style.listFarmsItem].join(' ')}
                onClick={() => setSelectedFarms(1)}
            >
                Coop1
            </div>
            <div className={style.listFarmsButtons}>
                <Button
                    label='Add Coop'
                    icon={<AddIcon />}
                />
            </div>
        </div>}
        
    </div>
  );
}

export default Menu;
