import styles from "./MessagesStack.module.scss";
// import MessageWithAlert from "./messageWithAlert/MessageWithAlert";
// import {ReactComponent as CompleteIcon} from '../../../assets/images/comlite.svg';
// import Message from "./message/Message";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";
// import {
//   addAlertsToState,
//   // getAlertsForUserByCycleIdThunk,
// } from "../../store/dashboardSlices/alertsForUserSlice";
// import useUpdate from "../../hooks/useUpdate";
import Filter from "./filters/Filters";
import Messages from "./Messages/Messages";
// import Checkbox from "../UI/Checkbox/Checkbox";
import { api } from "../../api/api";
dayjs.extend(utc);

function MessagesStack() {
  const { t, i18n } = useTranslation();
  const [listAlert, setListAlert] = useState([]);
  const [loader, setLoader] = useState(true);
  // const dispatch = useDispatch();
  // const today = dayjs();
  // const updatingStatus = useSelector((state) => state.updating.alerts);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  // const defineIsDateHeaderNeed = (message, index, stackOfMessages) => {
  //   if (index === 0) return true;
  //   return dayjs(message.created).format("DD/MM/YYYY") ===
  //     dayjs(stackOfMessages[index - 1].created).format("DD/MM/YYYY")
  //     ? false
  //     : true;
  // };

  // const defineHeader = (todayObject, messageDateString) => {
  //   const difference = todayObject.diff(
  //     dayjs(messageDateString).format("YYYY-MM-DD"),
  //     "day"
  //   );

  //   return difference === 0
  //     ? t("SENSORS.TODAY")
  //     : difference === 1
  //     ? t("SENSORS.YESTERDAY")
  //     : dayjs(messageDateString).format("DD/MM/YYYY");
  // };

  // const downloadingStatus = useSelector(
  //   (state) => state.alertsForUser.getAlertsForUser.status
  // );
  

  useEffect(() => {
    if(!chosenCycle._id) return; 
    setLoader(true);
    console.log("get alerts for user")
    api.dashboard.getAlertsForUser(chosenCycle._id).then(data => {
      setListAlert(data?.data || []);
      setLoader(false);
    });
  }, [chosenCycle])

// const makeRequest = (isNow, isUpdating) => {
//   console.log("makeRequestljakbfljkafhslkas!!!!!!!!!!!!!!!!")
//   api.alerts.getAlerts(chosenCycle._id).then(data => {
//     setListAlert(data?.data || []);
//   });
  // dispatch(
  //   getAlertsForUserByCycleIdThunk({ cycleId: chosenCycle._id, isUpdating })
  // );
// };

// useUpdate(makeRequest, 1);

  return (
    <section className={styles.container}>
      <Filter listAlert={listAlert}/>

      {loader ? (
        <div className={styles.spinnerContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div
          className={classNames(styles.messageRow)}
        >
          <div
            className={classNames(styles.messageContainer, {
              [styles.helperContainerLtr]: i18n.language === "en",
              [styles.helperContainerRtl]: i18n.language === "he",
            })}
          >
            <div className={classNames(styles.containerAlerts, styles.high)}>
              <div className={styles.header}>
                {t("SENSORS.HIGH")} ({(listAlert.length && listAlert?.filter(item => item.priority === 2)?.length) || 0})
              </div>
              <div className={styles.content}>
                <Messages 
                  listAlert={(listAlert.length && listAlert?.filter(item => item.priority === 2)) || []}
                  type="high"  
                />
              </div>
            </div>

            <div className={classNames(styles.containerAlerts, styles.medium)}>
              <div className={styles.header}>
                {t("SENSORS.MEDIUM")} ({(listAlert.length && listAlert?.filter(item => item.priority === 1)?.length) || 0})
              </div>
              <Messages 
                  listAlert={(listAlert.length && listAlert?.filter(item => item.priority === 1)) || []}
                  type="medium"  
                />
            </div>

            <div className={classNames(styles.containerAlerts, styles.low)}>
              <div className={styles.header}>
                {t("SENSORS.LOW")} ({(listAlert.length && listAlert?.filter(item => item.priority === 0)?.length) || 0})
              </div>
              <Messages 
                  listAlert={(listAlert.length && listAlert?.filter(item => item.priority === 0)) || []}
                  type="low"  
                />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default MessagesStack;
