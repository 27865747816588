import styles from "./Modal.module.scss";
import { useTranslation } from "react-i18next";


function Modal({contentModal, setShow}) {
  const { i18n, t } = useTranslation();

  return (
    <div className={styles.container}>
        <div className={styles.backgtound} onClick={() => setShow(false)}></div>
        <div className={styles.modal}>
            {contentModal}
        </div>
    </div>
  );
}

export default Modal;
