import styles from "./AverageData.module.scss";
import Chart from "./chart/Chart";
import { chooseColor } from "../../../../utils/chooseColor";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
// import {
//   addAverageDataToState,
//   getAverageDataThunk,
// } from "../../../../store/dashboardSlices/ventilationSlices/averageDataForUserSlice";
import { useTranslation } from "react-i18next";
// import useUpdate from "../../../../hooks/useUpdate";
import { api } from "../../../../api/api";

function AverageData() {
  const { t } = useTranslation();
  const [averageData, setAverageData] = useState(null);
  const [samples, setSamples] = useState(null);
  const [loader, setLoader] = useState(true);
  const [humidData, setHumidData] = useState(null);
  const [tempData, setTempData] = useState(null);
  // const dispatch = useDispatch();

  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  // const averageData = useSelector(
  //   (state) => state.averageDataForUser.getAverageData.averageData
  // );
  // const downloadingStatus = useSelector(
  //   (state) => state.averageDataForUser.getAverageData.status
  // );
  // const updatingStatus = useSelector((state) => state.updating.humTempAverage);

  // const humidData = averageData
  //   ? {
  //       dangerHigh: averageData.humidDangerHigh,
  //       dangerLow: averageData.humidDangerLow,
  //       max: averageData.humidMax,
  //       min: averageData.humidMin,
  //       maxTime: averageData.humidMaxTime,
  //       minTime: averageData.humidMinTime,
  //       now: averageData.humidNow,
  //       poorHigh: averageData.humidPoorHigh,
  //       poorLow: averageData.humidPoorLow,
  //       maxHumidSample: averageData.maxHumidSample,
  //       minHumidSample: averageData.minHumidSample,
  //     }
  //   : null;

  // const tempData = averageData
  //   ? {
  //       dangerHigh: averageData.tempDangerHigh,
  //       dangerLow: averageData.tempDangerLow,
  //       max: averageData.tempMax,
  //       min: averageData.tempMin,
  //       maxTime: averageData.tempMaxTime,
  //       minTime: averageData.tempMinTime,
  //       now: averageData.tempNow,
  //       poorHigh: averageData.tempPoorHigh,
  //       poorLow: averageData.tempPoorLow,
  //       maxTempSample: averageData.maxTempSample,
  //       minTempSample: averageData.minTempSample,
  //     }
  //   : null;

  // const samples = useSelector(
  //   (state) => state.averageDataForUser.getAverageData.samples
  // );

  useEffect(() => {
    // if (!chosenCycle?.settedGrowthDay) {
    //   dispatch(addAverageDataToState(null));
    // }

    // if (chosenCycle?._id && chosenCycle?.settedGrowthDay) {
      makeRequest();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCycle]);

  const makeRequest = () => {
    if(!chosenCycle._id) return;
    setLoader(true);
    api.dashboard.getAverageData(chosenCycle._id, chosenCycle.settedGrowthDay).then((data) => {
      setAverageData(data.averageData);
      setSamples(data.samples);
      if(data.averageData) {
        setTempData(
          {
            dangerHigh: data.averageData.tempDangerHigh,
            dangerLow: data.averageData.tempDangerLow,
            max: data.averageData.tempMax,
            min: data.averageData.tempMin,
            maxTime: data.averageData.tempMaxTime,
            minTime: data.averageData.tempMinTime,
            now: data.averageData.tempNow,
            poorHigh: data.averageData.tempPoorHigh,
            poorLow: data.averageData.tempPoorLow,
            maxTempSample: data.averageData.maxTempSample,
            minTempSample: data.averageData.minTempSample,
          }
        );
        setHumidData(
          {
            dangerHigh: data.averageData.humidDangerHigh,
            dangerLow: data.averageData.humidDangerLow,
            max: data.averageData.humidMax,
            min: data.averageData.humidMin,
            maxTime: data.averageData.humidMaxTime,
            minTime: data.averageData.humidMinTime,
            now: data.averageData.humidNow,
            poorHigh: data.averageData.humidPoorHigh,
            poorLow: data.averageData.humidPoorLow,
            maxHumidSample: data.averageData.maxHumidSample,
            minHumidSample: data.averageData.minHumidSample,
          }
        )
      }
      console.log("data.averageData - ", data.averageData)
      setLoader(false);
    });
    // const payload = {
    //   cycleId: chosenCycle._id,
    //   data: chosenCycle.settedGrowthDay,
    //   isUpdating,
    // };
    // dispatch(getAverageDataThunk(payload));
  };

  // useUpdate(makeRequest, 1);

  return (
    <div className={styles.container}>
      {loader ? (
        <div className={styles.spinnerContainer}>
          <CircularProgress />
        </div>
      ) : (
        averageData && (
          <>
            <div className={styles.temperatureInformation}>
              <div className={styles.logoTitle}>
                <svg
                  id="icon-temp"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="62"
                  viewBox="0 0 40 62"
                >
                  <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M-286.446-6173.833a10.3,10.3,0,0,0-1.427-5.236,10.409,10.409,0,0,0-3.364-3.477v-33.913a5.548,5.548,0,0,0-5.542-5.542,5.548,5.548,0,0,0-5.542,5.542v33.913a10.428,10.428,0,0,0-3.365,3.477,10.314,10.314,0,0,0-1.426,5.236,10.344,10.344,0,0,0,10.333,10.333A10.344,10.344,0,0,0-286.446-6173.833Zm-6.291-42.626v34.767l.375.217a8.864,8.864,0,0,1,4.416,7.642,8.841,8.841,0,0,1-8.832,8.832,8.843,8.843,0,0,1-8.833-8.832,8.865,8.865,0,0,1,4.418-7.642l.375-.217v-34.767a4.046,4.046,0,0,1,4.041-4.042A4.046,4.046,0,0,1-292.737-6216.458Zm1.3,42.726a5.134,5.134,0,0,0-5.136-5.134,5.134,5.134,0,0,0-5.134,5.134,5.134,5.134,0,0,0,5.134,5.136A5.135,5.135,0,0,0-291.44-6173.732Zm-1.5,0a3.64,3.64,0,0,1-3.637,3.635,3.64,3.64,0,0,1-3.635-3.635,3.641,3.641,0,0,1,3.635-3.635A3.641,3.641,0,0,1-292.939-6173.732Zm-13.118-13.193v-1.5h-9.693v1.5Zm0-10.041v-1.5h-9.693v1.5Zm0-10.039v-1.5h-9.693v1.5Zm0-8.924v-1.5h-9.693v1.5Z"
                    transform="translate(324.785 6223.75)"
                    fill="#414141"
                  />
                  <g
                    id="Rectangle_260"
                    data-name="Rectangle 260"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1"
                    opacity="0"
                  >
                    <rect width="40" height="62" stroke="none" />
                    <rect x="0.5" y="0.5" width="39" height="61" fill="none" />
                  </g>
                </svg>
                <span> {t("VENTILATION.TEMP_BUTTON")}</span>
              </div>
              <div className={styles.informations}>
                <div className={styles.maxMinInformation}>
                  <div className={styles.maxInformation}>
                    <div
                      className={styles.indicator}
                      style={{
                        backgroundColor: chooseColor(tempData.max, tempData),
                      }}
                    ></div>

                    <div className={styles.information}>
                      <span className={styles.time}>
                        {dayjs(tempData.maxTime).format("HH:mm")}
                      </span>
                      <span className={styles.minMax}>
                        {t("VENTILATION.MAX")}
                      </span>
                      <span
                        className={styles.index}
                        style={{
                          color: chooseColor(tempData.max, tempData),
                        }}
                      >
                        {Math.round(tempData.max)}°
                      </span>
                    </div>
                  </div>
                  <div className={styles.minInformation}>
                    <div
                      className={styles.indicator}
                      style={{
                        backgroundColor: chooseColor(tempData.min, tempData),
                      }}
                    ></div>
                    <div className={styles.information}>
                      <span className={styles.time}>
                        {dayjs(tempData.minTime).format("HH:mm")}
                      </span>
                      <span className={styles.minMax}>
                        {t("VENTILATION.MIN")}
                      </span>
                      <span
                        className={styles.index}
                        style={{
                          color: chooseColor(tempData.min, tempData),
                        }}
                      >
                        {Math.round(tempData.min)}°
                      </span>
                    </div>
                  </div>
                </div>

                <div className={styles.chart}>
                  <Chart
                    samples={samples.averageTemp}
                    lineName="Temperature"
                    range={tempData}
                  />
                </div>

                <div className={styles.actualInformation}>
                  <span className={styles.now}>
                    {t("VENTILATION.NOW_BUTTON")}
                  </span>
                  <br />

                  <span
                    className={styles.actualIndex}
                    style={{
                      color: chooseColor(tempData.now, tempData),
                    }}
                  >
                    {Math.round(tempData.now)}°
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.humidityInformation}>
              <div className={styles.logoTitle}>
                <svg
                  id="icon-_moist"
                  data-name="icon- moist"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="62"
                  viewBox="0 0 40 62"
                >
                  <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M-283.37-6290.048a15.371,15.371,0,0,0,4.692-3.148,14.98,14.98,0,0,0,3.222-4.592,13.4,13.4,0,0,0,1.2-5.522c0-6.042-3.624-10.144-7.13-14.108-2.959-3.346-6.017-6.807-6.885-11.447a.751.751,0,0,0-.737-.612.748.748,0,0,0-.737.612c-.869,4.64-3.926,8.1-6.883,11.447-3.5,3.964-7.126,8.066-7.126,14.108a13.429,13.429,0,0,0,1.2,5.522,15.013,15.013,0,0,0,3.224,4.592,15.371,15.371,0,0,0,4.692,3.148,14.214,14.214,0,0,0,5.63,1.174A14.216,14.216,0,0,0-283.37-6290.048Zm.866-26.377c3.473,3.93,6.753,7.642,6.753,13.115,0,6.89-6.191,12.936-13.25,12.936s-13.249-6.046-13.249-12.936c0-5.476,3.278-9.187,6.75-13.115,2.522-2.854,5.113-5.787,6.5-9.512C-287.62-6322.212-285.027-6319.278-282.5-6316.425Zm-7.492,23.55a11.119,11.119,0,0,0,4.492-.957,12.2,12.2,0,0,0,3.736-2.563,12.218,12.218,0,0,0,2.565-3.736,11.124,11.124,0,0,0,.958-4.494h-1.5a10.271,10.271,0,0,1-3.083,7.168,10.273,10.273,0,0,1-7.168,3.081h0v1.5Zm-15.5-10.77a.741.741,0,0,0,.224-.75,9.022,9.022,0,0,1,.462-4.422c.807-2.555,2.906-6.578,8.321-11.237a.752.752,0,0,0,.108-1.025c-.485-.634-1-1.264-1.5-1.871-1.969-2.393-3.829-4.652-4.379-7.8a.749.749,0,0,0-.739-.621.751.751,0,0,0-.739.621c-.548,3.149-2.407,5.411-4.376,7.8-2.279,2.768-4.632,5.631-4.632,9.875a10.229,10.229,0,0,0,1.85,5.829,10.355,10.355,0,0,0,4.622,3.734.771.771,0,0,0,.284.054A.736.736,0,0,0-305.5-6303.645Zm6.456-18.351c.343.418.694.845,1.036,1.274a34.463,34.463,0,0,0-5.59,6.188,19.97,19.97,0,0,0-2.69,5.41,12.4,12.4,0,0,0-.583,3.658,9.007,9.007,0,0,1-4.381-7.606c0-3.707,2.083-6.24,4.29-8.922A23.822,23.822,0,0,0-303-6327.95,23.716,23.716,0,0,0-299.042-6322Z"
                    transform="translate(314.14 6341.494)"
                    fill="#414141"
                  />
                  <g
                    id="Rectangle_261"
                    data-name="Rectangle 261"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1"
                    opacity="0"
                  >
                    <rect width="40" height="62" stroke="none" />
                    <rect x="0.5" y="0.5" width="39" height="61" fill="none" />
                  </g>
                </svg>
                <span> {t("VENTILATION.RH_BUTTON")}</span>
              </div>
              
              <div className={styles.informations}>
                <div className={styles.maxMinInformation}>
                  <div className={styles.maxInformation}>
                    <div
                      className={styles.indicator}
                      style={{
                        backgroundColor: chooseColor(humidData.max, humidData),
                      }}
                    ></div>
                    <div className={styles.information}>
                      <span className={styles.time}>
                        {dayjs(humidData.maxTime).format("HH:mm")}
                      </span>
                      <span className={styles.minMax}>
                        {t("VENTILATION.MAX")}
                      </span>
                      <span
                        className={styles.index}
                        style={{
                          color: chooseColor(humidData.max, humidData),
                        }}
                      >
                        {Math.round(humidData.max)}
                        <span className={styles.percent}>%</span>
                      </span>
                    </div>
                  </div>
                  <div className={styles.minInformation}>
                    <div
                      className={styles.indicator}
                      style={{
                        backgroundColor: chooseColor(humidData.min, humidData),
                      }}
                    ></div>
                    <div className={styles.information}>
                      <span className={styles.time}>
                        {dayjs(humidData.minTime).format("HH:mm")}
                      </span>
                      <span className={styles.minMax}>
                        {t("VENTILATION.MIN")}
                      </span>
                      <span
                        className={styles.index}
                        style={{
                          color: chooseColor(humidData.min, humidData),
                        }}
                      >
                        {Math.round(humidData.min)}
                        <span className={styles.percent}>%</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className={styles.chart}>
                  <Chart
                    samples={samples.averageHumid}
                    lineName="Humidity"
                    range={humidData}
                  />
                </div>

                <div className={styles.actualInformation}>
                  <span className={styles.now}>
                    {t("VENTILATION.NOW_BUTTON")}
                  </span>
                  <br />

                  <span
                    className={styles.actualIndex}
                    style={{
                      color: chooseColor(humidData.now, humidData),
                    }}
                  >
                    {Math.round(humidData.now)}
                    <span className={styles.percent}>%</span>
                  </span>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
}

export default AverageData;
