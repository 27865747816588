import styles from "./BarChart.module.scss";
import ReactEChart from "echarts-for-react";
import YAxis from "./yAxis/YAxis";
// import { useSelector } from "react-redux";
// import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
// import { useState } from "react";

function BarChart({ventilationData}) {
  const { t } = useTranslation();
  // const ventilationData = useSelector(
  //   (state) => state.ventilationDataForUser.getVentilationData.response
  // );
  // const downloadingStatus = useSelector(
  //   (state) => state.ventilationDataForUser.getVentilationData.status
  // );
  // const updatingStatus = useSelector((state) => state.updating.heatStress);
  console.log(ventilationData)

  const { values, range } = ventilationData
    ? ventilationData
    : { values: null, range: null };
  const data = values
    ? Object.entries(values)
    : [
        ["a", null],
        ["b", null],
        ["c", null],
      ];

  const { none, low, high } = range
    ? range
    : { none: undefined, low: undefined, high: undefined };

  const setColor = (value) => {
    return value < none
      ? { color: "#0092E8" }
      : value >= none && value < low
      ? { color: "#0ACF83" }
      : value >= low && value < high
      ? { color: "#FFB23A" }
      : { color: "#F24E1E" };
  };

  const eChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      top: "0%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: [
          `${t("VENTILATION.AREA")} ${data[0][0].toUpperCase()}`,
          `${t("VENTILATION.AREA")} ${data[1][0].toUpperCase()}`,
          `${t("VENTILATION.AREA")} ${data[2][0].toUpperCase()}`,
        ],
        axisTick: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        max: high,
        splitNumber: "9",
        axisLabel: { show: false },
      },
    ],
    textStyle: {
      fontWeight: "bold",
    },
    series: [
      {
        name: "Heat",
        type: "bar",
        barWidth: "50%",
        data: [
          {
            value: data[0][1],
            itemStyle: setColor(data[0][1]),
          },
          {
            value: data[1][1],
            itemStyle: setColor(data[1][1]),
          },
          {
            value: data[2][1],
            itemStyle: setColor(data[2][1]),
          },
        ],
      },
    ],
  };

  return (
    <>
      <div className={styles.container}>
        {range && (
          <>
            <div className={styles.chartContainer}>
              <ReactEChart
                option={eChartsOption}
                style={{ height: "100%" }}
              />
            </div>
            <YAxis range={range} />
          </>
        )}
      </div>
    </>
  );
}

export default BarChart;
