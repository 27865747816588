import { createSlice } from "@reduxjs/toolkit";
// import { api } from "../../../api/api";
// import {
//   extraReducers,
//   fulfilled,
//   pending,
//   rejected,
// } from "../../../utils/sharedExtraReducers";
// import { changeUpdatingPHIStatus } from "../../updatingSlice";

const initialState = {
  getPHIData: {
    response: null,
    status: null,
    error: null,
  },
};

// export const getPHIDataThunk = createAsyncThunk(
//   "PHIForUser/getPHIData",
//   async function (payload, { rejectWithValue, dispatch }) {
//     const { cycleId, data, isNow, isUpdating } = payload;
//     try {
//       if (isUpdating) dispatch(changeUpdatingPHIStatus("started"));
//       const response = await api.dashboard.getPHIData(cycleId, data, isNow);
//       dispatch(addPHIDataToState(response.data));
//       if (isUpdating) dispatch(changeUpdatingPHIStatus("finished"));
//     } catch (error) {
//       if (isUpdating) dispatch(changeUpdatingPHIStatus("finished"));
//       return rejectWithValue(error.message);
//     }
//   }
// );

const PHIForUserSlice = createSlice({
  name: "PHIForUser",
  initialState,
  reducers: {
    addPHIDataToState(state, action) {
      state.getPHIData.response = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getPHIDataThunk.pending, extraReducers(pending, "getPHIData"))
  //     .addCase(getPHIDataThunk.rejected, extraReducers(rejected, "getPHIData"))
  //     .addCase(
  //       getPHIDataThunk.fulfilled,
  //       extraReducers(fulfilled, "getPHIData")
  //     );
  // },
});

export const { addPHIDataToState } = PHIForUserSlice.actions;
export default PHIForUserSlice.reducer;
