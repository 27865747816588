import RoundSensorsPad from "./roundSensorsPad/RoundSensorsPad";
import styles from "./SensorsStack.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import {ReactComponent as Pen} from '../../../assets/images/pen.svg';
import { api } from "../../api/api";
import ModalEdit from "./ModalEdit/ModalEdit";

function SensorsStack() {
  const { t } = useTranslation();
  const [sensorsData, setSensorsData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  useEffect(() => {
    if (chosenCycle._id && chosenCycle.settedGrowthDay) {
      makeRequest();
    }
  }, [chosenCycle]);

  const makeRequest = (isNow, isUpdating) => {
    if(!chosenCycle._id) return;
    setLoader(true);
    api.dashboard.getSensorsStatus(chosenCycle._id, chosenCycle.settedGrowthDay).then((data) => {
      setSensorsData(data.data);
      setLoader(false);
    });
  };

  const defineData = (sensorsStatus, area) => {
    if (sensorsStatus !== null) {
      return sensorsStatus.find((sensor) => sensor.area === area);
    } else {
      return { status: "off", position: 0, devices: [] };
    }
  };

  return (
    <>
      <section className={styles.container}>
        <div className={styles.sensorHeader}>
          <h2 className={styles.header}>{t("SENSORS_DATA.SENSORS")}</h2>
          <div className={styles.buttons}>
            <div className={styles.editButton} onClick={() => setShowEditModal(true)}><Pen /></div>
          </div>
          
        </div>

        {loader ? (
          <div className={styles.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.sensorPads}>
                <RoundSensorsPad
                  areaName="A"
                  coolingPed={true}
                  firstData={defineData(sensorsData, "a")}
                />
              <RoundSensorsPad
                areaName="B"
                firstData={defineData(sensorsData, "b")}
              />
              <RoundSensorsPad
                areaName="C"
                firstData={defineData(sensorsData, "c")}
              />
          </div>
        )}
        <ModalEdit isShow={showEditModal} setShow={setShowEditModal}/>
      </section>
    </>
  );
}

export default SensorsStack;
