import styles from "./ModalEdit.module.scss";
import Modal from "../../../modal/Modal";
import { useTranslation } from "react-i18next";
import InputModal from "../../../UI/inputModal/Input";
import Button from "../../../UI/Button/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCountChickens } from "../../../../api/requests/coopsForUsers";
import { setActiveCycleCountChicken } from "../../../../store/coopsForUserSlice";

function ContentModal({isShow = false, setShow = () => {}}) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const activeCycle = useSelector((state) => state.coopsForUser.activeCycle);
  const [countChicken, setCountChicken] = useState(activeCycle.chickens);
  useEffect(() => {
    if(activeCycle.chickens) {
      setCountChicken(activeCycle.chickens);
    }
  }, [activeCycle])

  const handleSave = () => {
    setCountChickens(activeCycle.coopId, {chickens: countChicken}).then(() => {
      dispatch(setActiveCycleCountChicken(countChicken));
      setShow(false);
    });
  }

  return (  
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.top}>
            <h3>Sensors Setting</h3>
            <div className={styles.close} onClick={() => setShow(false)}>+</div>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.contentItem}>
            <div className={styles.label}>Edit number chicken</div>
            <InputModal 
              placeholder="0" 
              name="Number" 
              onClick={(e) => setCountChicken(e)}
              defaultValue={activeCycle.chickens}
              type="number"
              />
          </div>
        </div>

        <div className={styles.buttons} onClick={handleSave}>
          <Button label={'Save'}/>
        </div>
      </div>
    </>
  );
}

function ModalEdit({isShow = true, setShow}) {

  return (
    isShow && <>
      <Modal contentModal={<ContentModal isShow={isShow} setShow={setShow}/>}  setShow={setShow} />
    </>
  );
}

export default ModalEdit;
