import { makeRequest } from "../makeRequest";

export const login = (data) => {
  return makeRequest({
    method: "POST",
    url: "/Account/Login",
    data,
  });
};

export const getRefreshToken = () => {
  return makeRequest({
    method: "POST",
    url: "/Account/Refresh",
  });
};

export const getUserData = () => {
  return makeRequest({
    method: "GET",
    url: "/Account",
  });
};
